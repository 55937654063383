import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
let retryCount = 0;
const initialize = (openOnInit, onlyHide) => {
  if (!window.humany?.configure) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 100) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("humany chat init failed, window.humany not available");
      } else {
        initialize(openOnInit, onlyHide);
      }
    }, 100);
    return;
  }
  const widget = window.humany.widgets.all().find((widget2) => widget2.name.includes("chat") && widget2.state === "activated");
  if (!widget) {
    throw new Error("humany chat init failed, no active humany chat widget");
  }
  widget.events.subscribe("widget:move-outside-viewport", () => {
    event.triggerListeners("chat:end");
    window.raffleApi.show();
  });
  window.raffleApi.addEventListener("chat:start", () => {
    window.raffleApi.hide();
    widget.invoke("start");
  });
  event.triggerListeners("chat:ready");
  if (openOnInit) {
    event.triggerListeners("chat:start");
  }
};
export const init = (openOnInit, onlyHide) => {
  const css = document.createElement("style");
  css.type = "text/css";
  css.appendChild(document.createTextNode(".humany-trigger { visibility: hidden; }"));
  document.getElementsByTagName("head")[0].appendChild(css);
  if (onlyHide) {
    return;
  }
  initialize(openOnInit, onlyHide);
};
