import * as dixa from "./dixa.js";
import * as facebook from "./facebook.js";
import * as humany from "./humany.js";
import * as smartsupp from "./smartsupp.js";
import * as snap from "./snap.js";
import * as zendesk from "./zendesk.js";
import * as zoho from "./zoho.js";
const chatVendors = {
  dixa,
  facebook,
  humany,
  smartsupp,
  snap,
  zendesk,
  zoho
};
export function chatIntegrationInit({enabled, vendor}, openOnInit) {
  if (!enabled || !vendor) {
    return;
  }
  chatVendors[vendor].init(openOnInit, false);
}
export function chatIntegrationHide({
  enabled,
  vendor
}) {
  if (!enabled || !vendor) {
    return;
  }
  chatVendors[vendor].init(false, true);
}
