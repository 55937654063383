import uaParser from "../../_snowpack/pkg/ua-parser-js.js";
const supportedBrowsers = {
  Chrome: 71,
  Firefox: 80,
  Edge: 80,
  Opera: 55,
  Safari: 12
};
const getbrowserName = (parsed) => parsed?.browser?.name || "";
const getbrowserMajorVersion = (parsed) => parseInt(parsed?.browser?.major || "0");
const getOSMajorVersion = (parsed) => {
  const osVersionString = parsed?.os?.version;
  if (osVersionString) {
    return parseInt(osVersionString.split(".")[0]);
  }
  return 0;
};
const getOSName = (parsed) => parsed?.os?.name;
const browserSupport = (userAgent) => {
  const parsed = uaParser(userAgent);
  for (const name in supportedBrowsers) {
    const regx = new RegExp(name, "i");
    if (regx.test(getbrowserName(parsed)) && getbrowserMajorVersion(parsed) >= supportedBrowsers[name]) {
      return true;
    }
  }
  if (getOSName(parsed) === "iOS" && getOSMajorVersion(parsed) >= supportedBrowsers["Safari"]) {
    return true;
  }
  return false;
};
export default browserSupport;
