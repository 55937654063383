function parseHsl(color) {
  const hslRegex = /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/g;
  const res = hslRegex.exec(color);
  const obj = {
    h: parseInt(res[1]),
    s: parseInt(res[2]),
    l: parseInt(res[3])
  };
  if (Number.isInteger(obj.h) && Number.isInteger(obj.s) && Number.isInteger(obj.l)) {
    return obj;
  }
  throw new Error("parseHsl called with non hsl color string");
}
function stringifyHsl(obj) {
  return `hsl(${obj.h},${obj.s}%,${obj.l}%)`;
}
function darken(color, amount) {
  const obj = parseHsl(color);
  obj.l -= amount;
  return stringifyHsl(obj);
}
function lighten(color, amount) {
  const obj = parseHsl(color);
  obj.l += amount;
  return stringifyHsl(obj);
}
function hslToHex(color) {
  const hsl = parseHsl(color);
  hsl.l /= 100;
  const a = hsl.s * Math.min(hsl.l, 1 - hsl.l) / 100;
  const f = (n) => {
    const k = (n + hsl.h / 30) % 12;
    const color2 = hsl.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color2).toString(16).padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
export {parseHsl, stringifyHsl, darken, lighten, hslToHex};
