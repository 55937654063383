import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

export const FrameIdentifierClassname = "raffle-search-frame";
export const OriginKey = "raffle-search-client";
export const STORE_KEY = (id) => `STORE_DUMP-${id}`;
export const BACKEND_URL = {
  development: "../mocks",
  test: "../mocks",
  staging: "https://search-backend.staging.raffle.ai",
  production: "https://search-backend.raffle.ai"
}[__SNOWPACK_ENV__.SNOWPACK_PUBLIC_BACKEND_ENV];
export const FASTLY_URL = {
  development: "../mocks",
  test: "../mocks",
  staging: "https://searchcfg.staging.raffle.ai",
  production: "https://searchcfg.raffle.ai"
}[__SNOWPACK_ENV__.SNOWPACK_PUBLIC_BACKEND_ENV];
export const CLIENT_BASE_URL = {
  development: "",
  test: "",
  staging: "https://cdn.staging.raffle.ai/search",
  production: "https://cdn.raffle.ai/search"
}[__SNOWPACK_ENV__.SNOWPACK_PUBLIC_FRONTEND_ENV];
