import qs from "../../_snowpack/pkg/query-string.js";
import {BACKEND_URL, FASTLY_URL} from "../constants.js";
const url = (path, isFastly = false) => `${isFastly ? FASTLY_URL : BACKEND_URL}/${path}`;
export async function get({
  path,
  token,
  params,
  options = {},
  isFastly = false
}) {
  const response = await fetch(qs.stringifyUrl({url: url(path, isFastly), query: params}), {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      ...token ? {Authorization: `Bearer ${token}`} : {}
    },
    ...options
  });
  return handleResponse(response);
}
export async function post({path, body, token}) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...token ? {Authorization: `Bearer ${token}`} : {}
    },
    body: JSON.stringify(body)
  };
  const response = await fetch(url(path), requestOptions);
  return handleResponse(response);
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = data && data.message || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
