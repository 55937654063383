import isWithinInterval from "../../_snowpack/pkg/date-fns/esm/isWithinInterval.js";
import parseJSON from "../../_snowpack/pkg/date-fns/esm/parseJSON.js";
import {
  SupportChannel
} from "../types/index.js";
const matchRecurringType = (now, recurring) => {
  const dayOfWeek = now.getDay();
  switch (recurring.type) {
    case "monday":
      return dayOfWeek === 1;
    case "tuesday":
      return dayOfWeek === 2;
    case "wednesday":
      return dayOfWeek === 3;
    case "thursday":
      return dayOfWeek === 4;
    case "friday":
      return dayOfWeek === 5;
    case "saturday":
      return dayOfWeek === 6;
    case "sunday":
      return dayOfWeek === 0;
    case "weekday":
      return [1, 2, 3, 4, 5].includes(dayOfWeek);
    case "weekend":
      return [0, 6].includes(dayOfWeek);
    case "every_day":
      return true;
    default:
      return false;
  }
};
const parseTime = (str, now) => {
  const splits = str.split(":");
  const next = new Date(now);
  next.setHours(parseInt(splits[0]), parseInt(splits[1]), 0, 0);
  return next;
};
const withinRecurring = (now, recurring) => matchRecurringType(now, recurring) && isWithinInterval(now, {
  start: parseTime(recurring.start, now),
  end: parseTime(recurring.end, now)
});
export const channelIsAvailable = (now, availability) => {
  if (!availability || availability.always) {
    return true;
  }
  if (availability.single_closed?.some((interval) => isWithinInterval(now, {
    start: parseJSON(interval.start),
    end: parseJSON(interval.end)
  }))) {
    return false;
  }
  return availability.recurring_open?.some((recurring) => withinRecurring(now, recurring));
};
export const blindChannels = [SupportChannel.IFRAME, SupportChannel.CHAT];
const currentSupportChannel = (availableChannels) => {
  if (availableChannels.length > 1) {
    return void 0;
  }
  if (availableChannels.length === 1 && blindChannels.includes(availableChannels[0])) {
    return void 0;
  }
  if (availableChannels.length === 1) {
    return availableChannels[0];
  }
  return void 0;
};
export const supportChannelAvailability = (supportChannels) => {
  const now = new Date();
  const availableSupportChannels = Object.keys(supportChannels).reduce((channels, channelName) => supportChannels[channelName].enabled === true && channelIsAvailable(now, supportChannels[channelName].availability) ? [...channels, channelName] : channels, []);
  return {
    availableSupportChannels,
    currentSupportChannel: currentSupportChannel(availableSupportChannels)
  };
};
