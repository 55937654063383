/* src/host/frames/Element.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	append,
	append_styles,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	null_to_empty,
	safe_not_equal,
	src_url_equal,
	toggle_class
} from "../../../_snowpack/pkg/svelte/internal.js";

import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { ElementFrame } from '../../types/index.js';
import { get, connect, disconnect } from '../../sharedState/inHost.js';
import { FrameIdentifierClassname, CLIENT_BASE_URL } from '../../constants.js';

function add_css(target) {
	append_styles(target, "raffle__Element--1t9iaod", "div.raffle__Element--1t9iaod{position:absolute;top:0;left:0;width:100%;height:100%;transition:height 250ms ease-in-out;z-index:100}iframe.raffle__Element--1t9iaod{height:100%;width:100%;border:0;z-index:100}.expanded.raffle__Element--1t9iaod{height:400px}.relative.raffle__Element--1t9iaod{position:relative;height:700px}");
}

function create_fragment(ctx) {
	let div;
	let iframe;
	let iframe_class_value;
	let iframe_src_value;

	return {
		c() {
			div = element("div");
			iframe = element("iframe");
			attr(iframe, "class", iframe_class_value = "" + (null_to_empty(FrameIdentifierClassname) + " raffle__Element--1t9iaod"));
			attr(iframe, "data-testid", "raffle-frame__element");
			attr(iframe, "title", "raffle element client");
			if (!src_url_equal(iframe.src, iframe_src_value = `${CLIENT_BASE_URL}/clients/element.html`)) attr(iframe, "src", iframe_src_value);
			attr(div, "data-testid", "raffle-element-wrapper");
			attr(div, "class", "raffle__Element--1t9iaod");
			toggle_class(div, "expanded", /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED || /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED_RELATIVE);
			toggle_class(div, "relative", /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED_RELATIVE);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, iframe);
		},
		p(ctx, [dirty]) {
			if (dirty & /*elementFrame, ElementFrame*/ 1) {
				toggle_class(div, "expanded", /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED || /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED_RELATIVE);
			}

			if (dirty & /*elementFrame, ElementFrame*/ 1) {
				toggle_class(div, "relative", /*elementFrame*/ ctx[0] === ElementFrame.EXPANDED_RELATIVE);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let elementFrame = get('elementFrame');

	const handleElementFrame = n => {
		$$invalidate(0, elementFrame = n);
	};

	onMount(() => {
		connect('elementFrame', handleElementFrame);
	});

	onDestroy(() => {
		disconnect('elementFrame', handleElementFrame);
	});

	return [elementFrame];
}

class Element extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, add_css);
	}
}

export default Element;