/* src/host/frames/Launcher.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	append_styles,
	attr,
	check_outros,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	null_to_empty,
	safe_not_equal,
	space,
	src_url_equal,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { fade } from '../../../_snowpack/pkg/svelte/transition.js';
import { LauncherFrame } from '../../types/index.js';
import { get, connect, disconnect } from '../../sharedState/inHost.js';
import { FrameIdentifierClassname, CLIENT_BASE_URL } from '../../constants.js';
import NoScroll from './NoScroll.svelte.js';

function add_css(target) {
	append_styles(target, "raffle__Launcher--l6jlci", "div.raffle__Launcher--l6jlci{position:fixed;bottom:0;right:0;width:100%;height:100%;box-shadow:0px 9px 12px 0px rgba(0, 0, 0, 0.25);background-color:#ffffff;overflow:hidden;border-radius:0;border:0;transition:none;z-index:1000}iframe.raffle__Launcher--l6jlci{height:100%;width:100%;border:0;z-index:100}@media(min-width: 500px){div.raffle__Launcher--l6jlci{bottom:112px;right:40px;height:480px;max-height:calc(100vh - 8rem);width:350px;border-radius:12px;border:1px solid #e5e1e1;transition:height 0.25s ease-out}.collapsed.raffle__Launcher--l6jlci{height:64px}.collapsed__support.raffle__Launcher--l6jlci{height:100px}.expanded.raffle__Launcher--l6jlci{height:750px}.expanded__full.raffle__Launcher--l6jlci{height:800px}}");
}

// (33:0) {#if active}
function create_if_block(ctx) {
	let div;
	let iframe;
	let iframe_class_value;
	let iframe_src_value;
	let div_transition;
	let current;

	return {
		c() {
			div = element("div");
			iframe = element("iframe");
			attr(iframe, "class", iframe_class_value = "" + (null_to_empty(FrameIdentifierClassname) + " raffle__Launcher--l6jlci"));
			attr(iframe, "data-testid", "raffle-frame__launcher");
			attr(iframe, "title", "raffle launcher client");
			if (!src_url_equal(iframe.src, iframe_src_value = `${CLIENT_BASE_URL}/clients/launcher.html`)) attr(iframe, "src", iframe_src_value);
			attr(div, "data-testid", "raffle-search-launcher");
			attr(div, "class", "raffle__Launcher--l6jlci");
			toggle_class(div, "collapsed", /*launcherFrame*/ ctx[1] === LauncherFrame.COLLAPSED);
			toggle_class(div, "collapsed__support", /*launcherFrame*/ ctx[1] === LauncherFrame.COLLAPSED_SUPPORT);
			toggle_class(div, "expanded", /*launcherFrame*/ ctx[1] === LauncherFrame.EXPANDED);
			toggle_class(div, "expanded__full", /*launcherFrame*/ ctx[1] === LauncherFrame.EXPANDED_FULL);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, iframe);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*launcherFrame, LauncherFrame*/ 2) {
				toggle_class(div, "collapsed", /*launcherFrame*/ ctx[1] === LauncherFrame.COLLAPSED);
			}

			if (dirty & /*launcherFrame, LauncherFrame*/ 2) {
				toggle_class(div, "collapsed__support", /*launcherFrame*/ ctx[1] === LauncherFrame.COLLAPSED_SUPPORT);
			}

			if (dirty & /*launcherFrame, LauncherFrame*/ 2) {
				toggle_class(div, "expanded", /*launcherFrame*/ ctx[1] === LauncherFrame.EXPANDED);
			}

			if (dirty & /*launcherFrame, LauncherFrame*/ 2) {
				toggle_class(div, "expanded__full", /*launcherFrame*/ ctx[1] === LauncherFrame.EXPANDED_FULL);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
		}
	};
}

function create_fragment(ctx) {
	let noscroll;
	let t;
	let if_block_anchor;
	let current;

	noscroll = new NoScroll({
			props: {
				apply: /*active*/ ctx[0] && /*narrowViewport*/ ctx[2]
			}
		});

	let if_block = /*active*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			create_component(noscroll.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(noscroll, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const noscroll_changes = {};
			if (dirty & /*active, narrowViewport*/ 5) noscroll_changes.apply = /*active*/ ctx[0] && /*narrowViewport*/ ctx[2];
			noscroll.$set(noscroll_changes);

			if (/*active*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*active*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(noscroll.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(noscroll.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(noscroll, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let active = get('active');
	let launcherFrame = get('launcherFrame');
	let narrowViewport = get('narrowViewport');

	const handleActive = val => {
		$$invalidate(0, active = val);
	};

	const handleLauncherFrame = n => {
		$$invalidate(1, launcherFrame = n);
	};

	const handleNarrowViewport = n => {
		$$invalidate(2, narrowViewport = n);
	};

	onMount(() => {
		connect('active', handleActive);
		connect('launcherFrame', handleLauncherFrame);
		connect('narrowViewport', handleNarrowViewport);
	});

	onDestroy(() => {
		disconnect('active', handleActive);
		disconnect('launcherFrame', handleLauncherFrame);
		disconnect('narrowViewport', handleNarrowViewport);
	});

	return [active, launcherFrame, narrowViewport];
}

class Launcher extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, add_css);
	}
}

export default Launcher;