import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
let retryCount = 0;
export const init = (openOnInit, onlyHide) => {
  if (!window.FB?.CustomerChat) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 1500) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("Facebook chat init failed, window.FB.CustomerChat not available");
      } else {
        init(openOnInit, onlyHide);
      }
    }, 200);
    return;
  }
  window.FB.CustomerChat.hide();
  if (onlyHide) {
    return;
  }
  window.FB.Event.subscribe("customerchat.load", function() {
    window.FB.CustomerChat.hide();
  });
  window.FB.Event.subscribe("customerchat.dialogHide", function() {
    window.FB.CustomerChat.hide();
    window.raffleApi.show();
    event.triggerListeners("chat:end");
  });
  window.raffleApi.addEventListener("chat:start", () => {
    window.raffleApi.hide();
    window.FB.CustomerChat.showDialog();
  });
  event.triggerListeners("chat:ready");
  if (openOnInit) {
    event.triggerListeners("chat:start");
  }
};
