import {sentryClient} from "../host/sentry.js";
import {
  ActionName,
  SourceType,
  AnswerClickTarget
} from "../types/index.js";
export const ActionEventMap = {
  SEARCH_SUCCESS: "search",
  ANSWER_SELECT: "answer_click",
  ANSWER_REJECT: "answer_interaction_thumbs_down",
  ANSWER_ACCEPT: "answer_interaction_thumbs_up",
  ANSWER_INTERACTION_ADDITIONAL_CONTENT: "answer_interaction_additional_content",
  ANSWER_INTERACTION_CONTENT_LINK: "answer_interaction_content_link",
  ANSWER_INTERACTION_ORIGINAL_ANSWER: "answer_interaction_original_answer",
  GOTO_SUPPORT: "customer_service",
  SELECT_SUPPORT_CHANNEL: "support_channel"
};
const getModelRank = (state, section_id) => {
  if (section_id) {
    const section = state.search.sectionsDictionary[section_id];
    if (section) {
      return section.model_rank;
    }
  } else {
    return 0;
  }
};
const eventEmitter = (state, action, postEvent) => {
  const sharedEventBody = {
    instance_uid: state.instance_uid,
    knowledge_base_id: state.knowledgeBaseId,
    session_id: state.sessionId,
    url: state.url,
    device: state.deviceType
  };
  const firePostEvent = (name, properties) => {
    const eventBody = {
      ...sharedEventBody,
      name: ActionEventMap[name],
      properties
    };
    return postEvent(state.token, eventBody);
  };
  const getAnswerProps = (state2, answerId) => {
    const section = state2.search.data.find((item) => item.answer_id === answerId);
    if (section) {
      return {
        answer_version: section.answer_version,
        source_type: section.source_type
      };
    } else {
      return sentryClient.captureMessage(`Got invalid answer_id - ${answerId}. Returned default answer_version & source_type - (${{
        answer_version: 1,
        source_type: SourceType.MANUAL_UPLOAD
      }}) for event: ${action.type}`);
    }
  };
  switch (action.type) {
    case ActionName.SEARCH_SUCCESS: {
      const properties = {
        query: state.query,
        hint: state.hint,
        response: {
          model: action.payload.model_version,
          data: action.payload.data.map((section) => ({
            answer_id: section.answer_id,
            answer_version: section.answer_version,
            section_id: section.section_id,
            source_type: section.source_type
          }))
        }
      };
      return firePostEvent(action.type, properties);
    }
    case ActionName.ANSWER_SELECT: {
      const {answer_id, section_id, target} = action.payload;
      if (state.answersCache.click[answer_id] > 1)
        return;
      const sectionRank = getModelRank(state, section_id);
      const properties = {
        answer_id,
        ...getAnswerProps(state, answer_id),
        ...section_id && target === AnswerClickTarget.SECTION && {section_id},
        target,
        model_rank: sectionRank,
        visual_rank: state.search.data.findIndex((item) => item.answer_id === answer_id)
      };
      return firePostEvent(action.type, properties);
    }
    case ActionName.ANSWER_ACCEPT:
    case ActionName.ANSWER_REJECT: {
      const {answer_id} = action.payload;
      const properties = {
        answer_id,
        ...getAnswerProps(state, answer_id)
      };
      return firePostEvent(action.type, properties);
    }
    case ActionName.ANSWER_INTERACTION_ADDITIONAL_CONTENT: {
      return;
    }
    case ActionName.ANSWER_INTERACTION_CONTENT_LINK: {
      return;
    }
    case ActionName.ANSWER_INTERACTION_ORIGINAL_ANSWER: {
      const properties = {
        ...action.payload,
        ...getAnswerProps(state, action.payload.answer_id)
      };
      return firePostEvent(action.type, properties);
    }
    case ActionName.GOTO_SUPPORT: {
      const properties = {
        ...state.current.answer_id && {
          answer_id: state.current.answer_id,
          ...getAnswerProps(state, state.current.answer_id)
        }
      };
      return firePostEvent(action.type, properties);
    }
    case ActionName.SELECT_SUPPORT_CHANNEL: {
      const properties = {
        channel: action.payload
      };
      return firePostEvent(action.type, properties);
    }
    default:
      return;
  }
};
export default eventEmitter;
