export var Storage;
(function(Storage2) {
  Storage2[Storage2["LOCAL"] = 0] = "LOCAL";
  Storage2[Storage2["SESSION"] = 1] = "SESSION";
})(Storage || (Storage = {}));
const encode = (value) => {
  return JSON.stringify(value);
};
const decode = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return void 0;
  }
};
export const set = ({
  key,
  payload,
  storageType = 1
}) => {
  const storage = storageType === 0 ? window.localStorage : window.sessionStorage;
  storage.setItem(key, encode(payload));
};
export const get = ({
  key,
  storageType = 1,
  parse = true
}) => {
  const storage = storageType === 0 ? window.localStorage : window.sessionStorage;
  const result = storage.getItem(key);
  if (result) {
    return parse ? decode(result) : result;
  }
  return parse ? {} : void 0;
};
