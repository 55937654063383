/* src/host/frames/Sidebar.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	add_render_callback,
	append_styles,
	attr,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	null_to_empty,
	safe_not_equal,
	space,
	src_url_equal,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { fade } from '../../../_snowpack/pkg/svelte/transition.js';
import { ActionName } from '../../types/index.js';
import { FrameIdentifierClassname, CLIENT_BASE_URL } from '../../constants.js';
import NoScroll from './NoScroll.svelte.js';
import { get, connect, disconnect, dispatch } from '../../sharedState/inHost.js';

function add_css(target) {
	append_styles(target, "raffle__Sidebar--1jixnuy", ".backdrop.raffle__Sidebar--1jixnuy{display:none;position:fixed;width:100vw;height:100vh;top:0;left:0;right:0;bottom:0;background:rgba(119, 119, 119, 0.8);z-index:1000}.backdrop.open.raffle__Sidebar--1jixnuy{display:block}iframe.raffle__Sidebar--1jixnuy{position:fixed;top:0px;right:0px;height:100%;width:100%;max-width:100%;opacity:1;box-sizing:border-box;border:none;transform:translateX(120%);z-index:2147483647;transition-duration:200ms;transition-property:transform;transition-timing-function:ease-in-out;background-color:#fff}iframe.open.raffle__Sidebar--1jixnuy{transform:translateX(0)}@media(min-width: 500px){iframe.raffle__Sidebar--1jixnuy{left:initial;width:380px;max-width:380px}}");
}

function create_fragment(ctx) {
	let noscroll;
	let t0;
	let div;
	let div_transition;
	let t1;
	let iframe;
	let iframe_class_value;
	let iframe_src_value;
	let current;
	let mounted;
	let dispose;

	noscroll = new NoScroll({
			props: {
				apply: /*open*/ ctx[0] && /*narrowViewport*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(noscroll.$$.fragment);
			t0 = space();
			div = element("div");
			t1 = space();
			iframe = element("iframe");
			attr(div, "class", "backdrop raffle__Sidebar--1jixnuy");
			toggle_class(div, "open", /*open*/ ctx[0]);
			attr(iframe, "class", iframe_class_value = "" + (null_to_empty(FrameIdentifierClassname) + " raffle__Sidebar--1jixnuy"));
			attr(iframe, "data-testid", "raffle-frame__sidebar");
			attr(iframe, "title", "raffle sidebar client");
			if (!src_url_equal(iframe.src, iframe_src_value = `${CLIENT_BASE_URL}/clients/sidebar.html`)) attr(iframe, "src", iframe_src_value);
			toggle_class(iframe, "open", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			mount_component(noscroll, target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			insert(target, t1, anchor);
			insert(target, iframe, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*close*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const noscroll_changes = {};
			if (dirty & /*open, narrowViewport*/ 3) noscroll_changes.apply = /*open*/ ctx[0] && /*narrowViewport*/ ctx[1];
			noscroll.$set(noscroll_changes);

			if (dirty & /*open*/ 1) {
				toggle_class(div, "open", /*open*/ ctx[0]);
			}

			if (dirty & /*open*/ 1) {
				toggle_class(iframe, "open", /*open*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(noscroll.$$.fragment, local);

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			transition_out(noscroll.$$.fragment, local);
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			destroy_component(noscroll, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
			if (detaching) detach(t1);
			if (detaching) detach(iframe);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let open = get('sidebarOpen');
	let narrowViewport = get('narrowViewport');

	function handleOpen(val) {
		$$invalidate(0, open = val);
	}

	function handleNarrowViewport(n) {
		$$invalidate(1, narrowViewport = n);
	}

	function close() {
		dispatch({ type: ActionName.CLEAR });
	}

	onMount(() => {
		connect('sidebarOpen', handleOpen);
		connect('narrowViewport', handleNarrowViewport);
	});

	onDestroy(() => {
		disconnect('sidebarOpen', handleOpen);
		disconnect('narrowViewport', handleNarrowViewport);
	});

	return [open, narrowViewport, close];
}

class Sidebar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, add_css);
	}
}

export default Sidebar;