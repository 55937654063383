import {FrameIdentifierClassname, OriginKey} from "../constants.js";
const isIFrame = (input) => input !== null && input.tagName === "IFRAME";
export const postMessage = ({
  type,
  payload
}) => {
  const data = {
    origin: OriginKey,
    type,
    payload
  };
  window.document.querySelectorAll(`.${FrameIdentifierClassname}`).forEach((node) => {
    if (isIFrame(node) && node.contentWindow) {
      node.contentWindow.postMessage(data, "*");
    }
  });
};
