export var ActionName;
(function(ActionName2) {
  ActionName2["ACTIVE_TOGGLE"] = "ACTIVE_TOGGLE";
  ActionName2["FOCUS"] = "FOCUS";
  ActionName2["FOCUS_BLUR"] = "FOCUS_BLUR";
  ActionName2["ANSWER_CLOSE"] = "ANSWER_CLOSE";
  ActionName2["ANSWER_SELECT"] = "ANSWER_SELECT";
  ActionName2["ANSWER_REQUEST"] = "ANSWER_REQUEST";
  ActionName2["ANSWER_SUCCESS"] = "ANSWER_SUCCESS";
  ActionName2["ANSWER_FAILURE"] = "ANSWER_FAILURE";
  ActionName2["ANSWER_ACCEPT"] = "ANSWER_ACCEPT";
  ActionName2["ANSWER_REJECT"] = "ANSWER_REJECT";
  ActionName2["ANSWER_INTERACTION_ADDITIONAL_CONTENT"] = "ANSWER_INTERACTION_ADDITIONAL_CONTENT";
  ActionName2["ANSWER_INTERACTION_CONTENT_LINK"] = "ANSWER_INTERACTION_CONTENT_LINK";
  ActionName2["ANSWER_INTERACTION_ORIGINAL_ANSWER"] = "ANSWER_INTERACTION_ORIGINAL_ANSWER";
  ActionName2["CLEAR"] = "CLEAR";
  ActionName2["CLEAR_INPUT"] = "CLEAR_INPUT";
  ActionName2["INIT"] = "INIT";
  ActionName2["SEARCH_REQUEST"] = "SEARCH_REQUEST";
  ActionName2["SEARCH_SUCCESS"] = "SEARCH_SUCCESS";
  ActionName2["SEARCH_FAILURE"] = "SEARCH_FAILURE";
  ActionName2["SUGGESTIONS_REQUEST"] = "SUGGESTIONS_REQUEST";
  ActionName2["SUGGESTIONS_SUCCESS"] = "SUGGESTIONS_SUCCESS";
  ActionName2["SUGGESTIONS_FAILURE"] = "SUGGESTIONS_FAILURE";
  ActionName2["GOTO_SUPPORT"] = "GOTO_SUPPORT";
  ActionName2["SELECT_SUPPORT_CHANNEL"] = "SELECT_SUPPORT_CHANNEL";
  ActionName2["CLOSE_SUPPORT_SIDEBAR"] = "CLOSE_SUPPORT_SIDEBAR";
  ActionName2["CLEAR_SELECTED_CHANNEL"] = "CLEAR_SELECTED_CHANNEL";
  ActionName2["DISABLE_CHAT"] = "DISABLE_CHAT";
  ActionName2["GO_BACK"] = "GO_BACK";
  ActionName2["URL_CHANGE"] = "URL_CHANGE";
  ActionName2["RESET_ON_ERROR"] = "RESET_ON_ERROR";
})(ActionName || (ActionName = {}));
