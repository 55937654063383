import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
let retryCount = 0;
export const init = (openOnInit, onlyHide) => {
  if (!window.$zoho?.salesiq) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 100) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("zoho chat init failed, window.$zoho no availble");
      } else {
        init(openOnInit, onlyHide);
      }
    }, 100);
    return;
  }
  window.$zoho.salesiq.floatbutton.visible("hide");
  if (onlyHide) {
    return;
  }
  const offlineMsgElement = document.querySelector("#zsiq_maintitle.siqico-offline");
  if (offlineMsgElement) {
    event.triggerListeners("chat:disable");
    return;
  }
  window.$zoho.salesiq.chat.complete(function() {
    event.triggerListeners("chat:end");
    window.raffleApi.show();
  });
  window.$zoho.salesiq.floatwindow.minimize(function() {
    event.triggerListeners("chat:end");
    window.raffleApi.show();
  });
  window.raffleApi.addEventListener("chat:start", () => {
    window.raffleApi.hide();
    window.$zoho.salesiq.chat.start();
  });
  event.triggerListeners("chat:ready");
  if (openOnInit) {
    event.triggerListeners("chat:start");
  }
};
