/* src/host/frames/Overlay.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	append_styles,
	attr,
	check_outros,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	null_to_empty,
	safe_not_equal,
	space,
	src_url_equal,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { fade, fly } from '../../../_snowpack/pkg/svelte/transition.js';
import { cubicInOut } from '../../../_snowpack/pkg/svelte/easing.js';
import { get, connect, disconnect } from '../../sharedState/inHost.js';
import { ElementFrame } from '../../types/index.js';
import { FrameIdentifierClassname, CLIENT_BASE_URL } from '../../constants.js';
import NoScroll from './NoScroll.svelte.js';

function add_css(target) {
	append_styles(target, "raffle__Overlay--1wwgmud", "iframe.raffle__Overlay--1wwgmud{height:100%;width:100%;border:0;z-index:100}.overlay.raffle__Overlay--1wwgmud{position:fixed;width:100vw;height:100vh;top:0;left:0;right:0;bottom:0;background:rgba(119, 119, 119, 0.8);z-index:9999}.client__container.raffle__Overlay--1wwgmud{position:fixed;top:25px;left:30%;width:40%;height:48px;transition:height 250ms ease-in-out;z-index:99999;border-radius:23px;overflow:hidden}.client__container.expanded.raffle__Overlay--1wwgmud{height:700px}");
}

// (32:0) {#if active}
function create_if_block(ctx) {
	let noscroll;
	let t0;
	let div0;
	let div0_transition;
	let t1;
	let div1;
	let iframe;
	let iframe_class_value;
	let iframe_src_value;
	let div1_transition;
	let current;
	let mounted;
	let dispose;
	noscroll = new NoScroll({ props: { apply: true } });

	return {
		c() {
			create_component(noscroll.$$.fragment);
			t0 = space();
			div0 = element("div");
			t1 = space();
			div1 = element("div");
			iframe = element("iframe");
			attr(div0, "class", "overlay raffle__Overlay--1wwgmud");
			attr(iframe, "class", iframe_class_value = "" + (null_to_empty(FrameIdentifierClassname) + " raffle__Overlay--1wwgmud"));
			attr(iframe, "data-testid", "raffle-frame__overlay");
			attr(iframe, "title", "raffle overlay client");
			if (!src_url_equal(iframe.src, iframe_src_value = `${CLIENT_BASE_URL}/clients/element.html`)) attr(iframe, "src", iframe_src_value);
			attr(div1, "class", "client__container raffle__Overlay--1wwgmud");
			toggle_class(div1, "expanded", /*isExpanded*/ ctx[0]);
		},
		m(target, anchor) {
			mount_component(noscroll, target, anchor);
			insert(target, t0, anchor);
			insert(target, div0, anchor);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, iframe);
			current = true;

			if (!mounted) {
				dispose = listen(div0, "click", /*close*/ ctx[2]);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*isExpanded*/ 1) {
				toggle_class(div1, "expanded", /*isExpanded*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(noscroll.$$.fragment, local);

			add_render_callback(() => {
				if (!div0_transition) div0_transition = create_bidirectional_transition(div0, fade, {}, true);
				div0_transition.run(1);
			});

			add_render_callback(() => {
				if (!div1_transition) div1_transition = create_bidirectional_transition(
					div1,
					fly,
					{
						delay: 150,
						duration: 300,
						x: 0,
						y: -500,
						opacity: 0,
						easing: cubicInOut
					},
					true
				);

				div1_transition.run(1);
			});

			current = true;
		},
		o(local) {
			transition_out(noscroll.$$.fragment, local);
			if (!div0_transition) div0_transition = create_bidirectional_transition(div0, fade, {}, false);
			div0_transition.run(0);

			if (!div1_transition) div1_transition = create_bidirectional_transition(
				div1,
				fly,
				{
					delay: 150,
					duration: 300,
					x: 0,
					y: -500,
					opacity: 0,
					easing: cubicInOut
				},
				false
			);

			div1_transition.run(0);
			current = false;
		},
		d(detaching) {
			destroy_component(noscroll, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div0);
			if (detaching && div0_transition) div0_transition.end();
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			if (detaching && div1_transition) div1_transition.end();
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*active*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*active*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*active*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const elementFrame = get('elementFrame');
	let isExpanded = elementFrame === ElementFrame.EXPANDED || elementFrame === ElementFrame.EXPANDED_RELATIVE;
	let active = get('active');

	function handleExpanded(val) {
		$$invalidate(0, isExpanded = val === ElementFrame.EXPANDED || val === ElementFrame.EXPANDED_RELATIVE);
	}

	function handleActive(val) {
		$$invalidate(1, active = val);
	}

	function close() {
		$$invalidate(1, active = false);
	}

	onMount(() => {
		connect('elementFrame', handleExpanded);
		connect('active', handleActive);
	});

	onDestroy(() => {
		disconnect('elementFrame', handleExpanded);
		disconnect('active', handleActive);
	});

	return [isExpanded, active, close];
}

class Overlay extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, add_css);
	}
}

export default Overlay;