import {get, post} from "../utils/fetchWrapper.js";
export const INSTANCE = "v1/resource/instance";
export const fetchInstance = async (uid) => get({
  path: INSTANCE,
  params: {uid},
  isFastly: true
});
export const EVENTS = "v1/events";
export const postEvent = async (token, body) => post({
  path: EVENTS,
  body: [body],
  token
});
