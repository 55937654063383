import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
const getState = () => {
  const data = JSON.parse(window.localStorage.getItem("dixa-widget") || "{}");
  return (data.conversation?.messages?.length || 0) > 1;
};
const hideChatWrapper = () => {
  const wrapper = document.getElementById("dixa-iframe-wrapper");
  if (wrapper) {
    wrapper.style.display = "none";
  }
};
let retryCount = 0;
const showChatWrapper = () => {
  const elm = document.getElementById("dixa-iframe-wrapper");
  if (!elm) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 100) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("Dixa chat init failed, wrapper element not available");
      } else {
        showChatWrapper();
      }
    }, 100);
  } else {
    elm.style.display = "block";
  }
};
let initialMinimizedFired = false;
export const init = (openOnInit, onlyHide) => {
  const earlyhideStyle = document.createElement("style");
  earlyhideStyle.innerHTML = `#dixa-widget-wrapper { display: none; }`;
  document.head.appendChild(earlyhideStyle);
  if (onlyHide) {
    return;
  }
  let conversation = openOnInit && getState();
  window.raffleApi.addEventListener("chat:start", () => {
    showChatWrapper();
    earlyhideStyle.remove();
    window.raffleApi.hide();
    window._dixa("api.setView", "conversation");
  });
  window._dixa("api.subscribe", "onConversationStarted", () => {
    conversation = true;
  });
  window._dixa("api.subscribe", "onMessageAdded", () => {
    conversation = true;
  });
  window._dixa("api.subscribe", "onConversationEnded", () => {
    conversation = false;
  });
  window._dixa("api.subscribe", "onViewChanged", (e) => {
    if (e.view === "minimized" && initialMinimizedFired && conversation === false) {
      event.triggerListeners("chat:end");
      hideChatWrapper();
      window.raffleApi.show();
    }
    initialMinimizedFired = true;
  });
  event.triggerListeners("chat:ready");
  if (openOnInit) {
    event.triggerListeners("chat:start");
  }
};
