const da = {
  "startButton.label": "Få svar hurtigt",
  "backButton.label": "Tilbage",
  "closeButton.label": "Luk",
  "initialPopover.title": "Leder du efter et hurtigt svar?",
  "initialPopover.subtitle": "Klik her, indtast et spørgsmål og få et svar.",
  "support.title": "Kontakt vores kundeservice",
  "support.subtitle":
    "Kontakt vores kundeservice via en af mulighederne nedenfor",
  "support.email.title": "Send os en email",
  "support.email.subtitle":
    "Send os en email og vi vil vende tilbage hurtigst muligt",
  "support.iframe.title": "Send os en besked",
  "support.iframe.subtitle":
    "Send os en besked og vi vil vende tilbage hurtigst muligt",
  "support.phone.title": "Telefon",
  "support.phone.subtitle":
    "Se vores telefonnummer og åbningstider for kundeservice",
  "support.chat.title": "Chat",
  "support.chat.subtitle": "med vores kundeservice",
  "support.askAnother": "Stil et nyt spørgsmål",
  "support.error.title": "Noget gik galt",
  "support.error.description": "Tjek din internetforbindelse og prøv igen",
  "form.title": "Stil et spørgsmål",
  "form.titleNew": "Stil et nyt spørgsmål",
  "form.placeholder": "Skriv dit spørgsmål her",
  "form.buttonLabel": "Spørg",
  "form.validationError": "Du skal stille et spørgsmål",
  "answer.supportLabel": "Kontakt vores kundeservice her",
  "answer.showMore": "Læs mere",
  "answer.error.title": "Noget gik galt...",
  "answer.error.description": "Tjek din internetforbindelse og prøv igen",
  "answers.a11y.summary": "Svar [](index) af [](count)",
  "answers.a11y.tab": "Gå til svar [](index)",
  "noAnswer.label":
    "Det ser ud til, at dit spørgsmål ikke er dækket af de emner, som vi kan svare på. \n \nPrøv at spørge igen, eller kontakt kundeservice.",
  "feedback.positive.label": "Ja",
  "feedback.positive.ariaLabel": "Dette svar var nyttigt",
  "feedback.negative.label": "Nej",
  "feedback.negative.ariaLabel": "Dette svar var ikke nyttigt",
  "feedback.label": "Var dette svar nyttigt?",
  "feedback.label.reviewed": "Tak for din feedback",
  "success.title":
    "Tak for din feedback. Vi er glade for, at du kunne bruge svaret.",
  "success.button.title": "Tilbage til svar",
  "phone.title": "Ring til os",
  "phone.subtitle": "Du er velkommen til at ringe på",
  "phone.openingHoursTitle": "Vores åbningstider er:",
  "phone.askAnother": "Stil et nyt spørgsmål",
  "email.title": "Kontakt os via e-mail",
  "email.subtitle":
    "Vi sender dit spørgsmål videre til en fra vores team, som vil kontakte dig hurtigst muligt.",
  "email.subtitle_ca":
    "Vi besvarer spørgsmål hurtigst muligt. Får du ikke svar indenfor 2 hverdage, er du velkommen til at ringe til os.",
  "email.userEmailPlaceholder": "Skriv din e-mail her",
  "email.messagePlaceholder": "Skriv beskeden her",
  "email.error.title": "Kunne ikke sende beskeden",
  "email.error.subtitle":
    "Prøv igen, og hvis problemet fortsat forekommer, benyt en af de andre kontaktmuligheder.",
  "email.send": "Send",
  "email.sending": "Sender...",
  "email.sendCopyToSelf": "Send kopi til mig selv",
  "email.messageTemplate":
    "Hej kundeservice\n \nJeg har et spørgsmål: [](question)",
  "email.messageTemplatePostProcess": "Fra: [](userEmail)\n \n[](message)",
  "emailSuccess.title": "Vi har modtaget din e-mail",
  "emailSuccess.subtitle":
    "En fra vores team kontakter dig på den e-mailadresse, du har angivet.",
  "emailSuccess.finish": "Afslut",
  "sidebar.close": "Luk",
  "sidebar.originalLink": "Se original",
  "topquestions.title": "Hvad andre har spurgt om:",
  "globalError.description": "Noget gik galt.",
  "globalError.retryButtonLabel": "Prøv igen",
  expand: "Se mere",
  collapse: "Se mindre",
};

const en = {
  "startButton.label": "Get a quick answer",
  "backButton.label": "Back",
  "closeButton.label": "Close",
  "initialPopover.title": "Are you looking for a quick answer?",
  "initialPopover.subtitle":
    "Click here, type in your question and get an answer",
  "support.title": "Contact our Customer Service",
  "support.subtitle":
    "Contact our Customer Service via one of the options below",
  "support.email.title": "Send us an email",
  "support.email.subtitle":
    "Send us an email and we will get back to you as soon and possible",
  "support.iframe.title": "Send us a message",
  "support.iframe.subtitle":
    "Send us a message and we will get back to you as soon as possible",
  "support.phone.title": "Phone",
  "support.phone.subtitle": "See our phone number and opening hours",
  "support.chat.title": "Chat",
  "support.chat.subtitle": "with out customer support",
  "support.askAnother": "Ask a new question",
  "support.error.title": "Something went wrong...",
  "support.error.description": "Check your network connection and try again",
  "form.title": "Ask a question",
  "form.titleNew": "Ask a new question",
  "form.placeholder": "Write your question here",
  "form.buttonLabel": "Ask",
  "form.validationError": "You have to type a question",
  "answer.supportLabel": "Contact our Customer Service here",
  "answer.showMore": "Read more",
  "answer.error.title": "Something went wrong...",
  "answer.error.description": "Check your network connection and try again",
  "answers.a11y.summary": "Answer [](index) of [](count)",
  "answers.a11y.tab": "Goto answer [](index)",
  "noAnswer.label":
    "It looks like your question isn't covered by the subjects that we can answer. \n \nTry to ask again or contact Customer Service.",
  "feedback.positive.label": "Yes",
  "feedback.positive.ariaLabel": "This answer was helpful",
  "feedback.negative.label": "No",
  "feedback.negative.ariaLabel": "This answer was not helpful",
  "feedback.label": "Was this answer helpful?",
  "feedback.label.reviewed": "Thanks for your feedback",
  "success.title":
    "Thanks for your feedback, we're glad you could use the answer.",
  "success.button.title": "Back to answer",
  "phone.title": "Call us",
  "phone.subtitle": "You are welcome to call us at",
  "phone.openingHoursTitle": "Our opening hours are:",
  "phone.askAnother": "Ask a new question",
  "email.title": "Contact us via email",
  "email.subtitle":
    "We will pass on your question to one of our team members, who will contact you as soon as possible.",
  "email.userEmailPlaceholder": "Write your email here",
  "email.messagePlaceholder": "Write your message here",
  "email.error.title": "Unable to send the message",
  "email.error.subtitle":
    "Try again and if the issue persists use another of our support channels.",
  "email.send": "Send",
  "email.sending": "Sending… ",
  "email.sendCopyToSelf": "Send a copy to myself",
  "email.messageTemplate":
    "Hi Customer Service,\n \nI have a question: [](question)",
  "email.messageTemplatePostProcess": "From: [](userEmail)\n \n[](message)",
  "emailSuccess.title": "We have received your email",
  "emailSuccess.subtitle":
    "A member of our team will contact you on the email address you provided.",
  "emailSuccess.finish": "Finish",
  "sidebar.close": "Close",
  "sidebar.originalLink": "See original",
  "topquestions.title": "What others have asked:",
  "globalError.description": "Something went wrong.",
  "globalError.retryButtonLabel": "Try again",
  expand: "Expand",
  collapse: "Collapse",
};

const de = {
  "startButton.label": "Schnelle Antwort kriegen",
  "backButton.label": "Zurück",
  "closeButton.label": "Schließen",
  "initialPopover.title": "Suchen Sie eine schnelle Antwort?",
  "initialPopover.subtitle": "Bitte geben Sie hier Ihre Frage ein",
  "support.title": "Kontaktieren Sie unseren Kundenservice",
  "support.subtitle":
    "Wählen Sie eine der folgenden Möglichkeiten aus, um unseren Kundenservice zu kontaktieren",
  "support.email.title": "Schicken Sie uns eine E-Mail",
  "support.email.subtitle":
    "Schicken Sie uns eine E-Mail. Wir melden uns schnellstmöglich bei Ihnen.",
  "support.iframe.title": "Schicken Sie uns eine Nachricht",
  "support.iframe.subtitle":
    "Schicken Sie uns eine Nachricht. Wir melden uns schnellstmöglich bei Ihnen.",
  "support.phone.title": "Telefon",
  "support.phone.subtitle":
    "Telefonnummer und Öffnungszeiten des Kundenservice",
  "support.chat.title": "Chatten",
  "support.chat.subtitle": "Sie ohne Kundenbetreuung",
  "support.askAnother": "Neue Frage stellen",
  "support.error.title": "Etwas ist schiefgelaufen",
  "support.error.description":
    "Kontrollieren Sie bitte Ihre Internetverbindung und versuchen Sie nochmal",
  "form.title": "Frage stellen",
  "form.titleNew": "Neue Frage stellen",
  "form.placeholder": "Geben Sie hier Ihre Frage ein",
  "form.buttonLabel": "Frage abschicken",
  "form.validationError": "Sie müssen eine Frage stellen",
  "answer.supportLabel": "Hier können Sie unseren Kundenservice kontaktieren",
  "answer.showMore": "Mehr lesen",
  "answer.error.title": "Etwas ist schiefgelaufen...",
  "answer.error.description":
    "Kontrollieren Sie bitte Ihre Internetverbindung und versuchen Sie nochmal",
  "answers.a11y.summary": "Antwort [](index) von [](count)",
  "answers.a11y.tab": "Auf Antwort [](index) gehen",
  "noAnswer.label":
    "Wir können leider Ihre Frage nicht beantworten. \n \nVersuchen Sie bitte erneut zu fragen oder setzen Sie sich mit unserem Kundenservice in Kontakt.",
  "feedback.positive.label": "Ja",
  "feedback.positive.ariaLabel": "Diese Antwort hat mir weitergeholfen",
  "feedback.negative.label": "Nein",
  "feedback.negative.ariaLabel": "Diese Antwort hat mir nicht weitergeholfen",
  "feedback.label": "Ist diese Antwort hilfreich?",
  "feedback.label.reviewed": "Danke für Ihr Feedback",
  "success.title":
    "Danke für Ihr Feedback. Wir sind froh, Ihnen weiterhelfen zu können.",
  "success.button.title": "Zurück zur Antwort",
  "phone.title": "Rufen Sie uns an",
  "phone.subtitle": "Sie können uns gerne auf folgende Telefonnummer anrufen",
  "phone.openingHoursTitle": "Unsere Öffnungszeiten lauten:",
  "phone.askAnother": "Stellen Sie eine neue Frage",
  "email.title": "Schreiben Sie uns eine E-Mail",
  "email.subtitle":
    "Ihre Anfrage wird an einen unserer Mitarbeiter weitergegeben und wir werden schnellst möglich auf Sie zurückkommen.",
  "email.userEmailPlaceholder": "Schreiben Sie hier Ihre E-Mail-Adresse",
  "email.messagePlaceholder": "Schreiben Sie hier Ihre Nachricht",
  "email.error.title": "Die Nachricht konnte nicht zugestellt werden",
  "email.error.subtitle":
    "Bitte versuchen es Sie nochmal. Wenn der Fehler erneut auftritt, benutzen Sie bitte eine unserer anderen Kontaktmöglichkeiten.",
  "email.send": "Senden",
  "email.sending": "Nachricht wird gesendet...",
  "email.sendCopyToSelf": "Eine Blindkopie an mich schicken",
  "email.messageTemplate":
    "Lieber Kundenservice\n \nIch habe eine Frage: [](question)",
  "email.messageTemplatePostProcess": "Von: [](userEmail)\n \n[](message)",
  "emailSuccess.title": "Wir haben Ihre E-Mail erhalten",
  "emailSuccess.subtitle":
    "Ein_e Mitarbeiter_in wird Sie auf die von Ihnen angegebene E-Mail kontaktieren.",
  "emailSuccess.finish": "Fertig",
  "sidebar.close": "Schließen",
  "sidebar.originalLink": "Original ansehen",
  "topquestions.title": "Was andere gefragt haben:",
  "globalError.description": "Etwas ist schief gelaufen",
  "globalError.retryButtonLabel": "Versuchen Sie nochmal",
  expand: "Mehr sehen",
  collapse: "Weniger sehen",
};

const fr = {
  "startButton.label": "Optenez une réponse rapide",
  "backButton.label": "Précédent",
  "closeButton.label": "Fermer",
  "initialPopover.title": "Cherchez-vous une réponse rapide?",
  "initialPopover.subtitle":
    "Cliquez ici, saisissez votre question et obtenez une réponse",
  "support.title": "Contactez notre service client",
  "support.subtitle": `Contactez notre service client via l'une des options ci-dessous`,
  "support.email.title": "Envoyez-nous un e-mail",
  "support.email.subtitle":
    "Envoyez-nous un e-mail et nous vous répondrons dans les plus brefs délais",
  "support.iframe.title": "Envoyez-nous un message",
  "support.iframe.subtitle":
    "​Envoyez-nous un message et nous vous répondrons dans les plus brefs délais",
  "support.phone.title": "Téléphone",
  "support.phone.subtitle":
    "​Voir notre numéro de téléphone et nos heures d'ouverture",
  "support.chat.title": "Discuter",
  "support.chat.subtitle": "avec notre service client​",
  "support.askAnother": "Posez une nouvelle question ",
  "support.error.title": "​Une erreur s'est produite ...",
  "support.error.description": "Vérifiez votre connexion réseau et réessayez",
  "form.title": "Demandez une question",
  "form.titleNew": "Demandez une nouvelle question ",
  "form.placeholder": "​Écrivez votre question ici",
  "form.buttonLabel": "Demandez",
  "form.validationError": "Vous devez taper une question",
  "answer.supportLabel": "​Contactez notre service client ici",
  "answer.showMore": "Lire la suite",
  "answer.error.title": "Une erreur s’est produite...",
  "answer.error.description": "​Vérifiez votre connexion réseau et réessayez",
  "answers.a11y.summary": "Réponse[](index) sur [](count)",
  "answers.a11y.tab": "Aller à la réponse [](index)",
  "noAnswer.label":
    "​Il semble que votre question ne soit pas couverte par les sujets auxquels nous pouvons répondre. \n \n​Essayez de demander à nouveau ou contactez le service client.",
  "feedback.positive.label": "Oui",
  "feedback.positive.ariaLabel": "​Cette réponse a été utile ",
  "feedback.negative.label": "Non",
  "feedback.negative.ariaLabel": "​Cette réponse n'a pas été utile",
  "feedback.label": "​Cette réponse vous a-t-elle été utile?",
  "feedback.label.reviewed": "​Merci pour vos commentaires",
  "success.title":
    "​Merci pour vos commentaires, nous sommes heureux que vous ayez pu utiliser la réponse.",
  "success.button.title": "Retour à la réponse",
  "phone.title": "​Appelez-nous",
  "phone.subtitle": "Vous êtes invités à nous appeler au",
  "phone.openingHoursTitle": `Nos heures d'ouverture sont:`,
  "phone.askAnother": "​Poser une nouvelle question",
  "email.title": "​Contactez-nous par e-mail",
  "email.subtitle":
    "Nous transmettrons votre question à l'un des membres de notre équipe, qui vous contactera dans les plus brefs délais.",
  "email.userEmailPlaceholder": "Écrivez votre email ici",
  "email.messagePlaceholder": "Ecrivez votre message ici​",
  "email.error.title": `Impossible d'envoyer le message`,
  "email.error.subtitle": `Réessayez et si le problème persiste, utilisez un autre de nos canaux d'assistance.`,
  "email.send": "​Envoyer",
  "email.sending": "​Envoi en cours...",
  "email.sendCopyToSelf": "​Envoyer une copie à moi-même",
  "email.messageTemplate":
    "Bonjour service client,\n \nJ’ai une question: [](question)",
  "email.messageTemplatePostProcess": "From: [](userEmail)\n \n[](message)",
  "emailSuccess.title": "​Nous avons reçu votre e-mail",
  "emailSuccess.subtitle":
    "Un membre de notre équipe vous contactera à l'adresse e-mail que vous avez fournie.",
  "emailSuccess.finish": "Finir",
  "sidebar.close": "Fermer",
  "sidebar.originalLink": "Voir l’original",
  "topquestions.title": "​Ce que les autres ont demandé:",
  "globalError.description": "Une erreur s'est produite.",
  "globalError.retryButtonLabel": "Réessayez",
  expand: "Voir plus",
  collapse: "Voir moins",
};

const sv = {
  "startButton.label": "Få svar snabbt",
  "backButton.label": "Tillbaka",
  "closeButton.label": "Stäng",
  "initialPopover.title": "Letar du efter ett snabbt svar?",
  "initialPopover.subtitle": "Klicka här, ange en fråga och få svar.",
  "support.title": "Kontakta vår kundtjänst",
  "support.subtitle": "Kontakta vår kundtjänst via ett av alternativen nedan",
  "support.email.title": "Skicka oss ett mail",
  "support.email.subtitle":
    "Skicka oss ett mail och vi kontaktar dig så snart som möjligt",
  "support.iframe.title": "Skicka oss ett meddelande",
  "support.iframe.subtitle":
    "Skicka oss ett meddelande och vi kontaktar dig så snart som möjligt",
  "support.phone.title": "Telefon",
  "support.phone.subtitle":
    "Se vårt telefonnummer och öppettider för kundtjänst",
  "support.chat.title": "Chat",
  "support.chat.subtitle": "med vår kundtjänst",
  "support.askAnother": "Ställ en ny fråga",
  "support.error.title": "Något gick fel",
  "support.error.description":
    "Kontrollera din internetanslutning och försök igen",
  "form.title": "Ställ en fråga",
  "form.titleNew": "Ställ en ny fråga",
  "form.placeholder": "Skriv din fråga här",
  "form.buttonLabel": "Fråga",
  "form.validationError": "Du måste ställa en fråga",
  "answer.supportLabel": "Kontakta vår kundtjänst här",
  "answer.showMore": "Läs mer",
  "answer.error.title": "Något gick fel...",
  "answer.error.description":
    "Kontrollera din internetanslutning och försök igen",
  "answers.a11y.summary": "Svar [](index) av [](count)",
  "answers.a11y.tab": "Gå till svaret [](index)",
  "noAnswer.label":
    "Det verkar som om din fråga inte täcks av de ämnen som vi kan svara på. \n \nFörsök att fråga igen eller kontakta kundtjänst.",
  "feedback.positive.label": "Ja",
  "feedback.positive.ariaLabel": "Detta svar var hjälpsamt",
  "feedback.negative.label": "Nej",
  "feedback.negative.ariaLabel": "Detta svar var inte hjälpsamt",
  "feedback.label": "Var detta svar hjälpsamt?",
  "feedback.label.reviewed": "Tack för din feedback",
  "success.title":
    "Tack för din feedback. Vi är glada för, att du kunde använda svaret.",
  "success.button.title": "Tillbaka till svaret",
  "phone.title": "Ring oss",
  "phone.subtitle": "Du är välkommen att ringa oss på",
  "phone.openingHoursTitle": "Våra öppettider är:",
  "phone.askAnother": "Ställ en ny fråga",
  "email.title": "Kontakta oss via e-post",
  "email.subtitle":
    "Vi skickar din fråga till en från vårt team som kontaktar dig så snart som möjligt.",
  "email.subtitle_ca":
    "Vi svarar på frågor så snart som möjligt. Om du inte får svar inom två arbetsdagar är du välkommen att ringa oss.",
  "email.userEmailPlaceholder": "Ange din e-postadress här",
  "email.messagePlaceholder": "Skriv meddelandet här",
  "email.error.title": "Det gick inte att skicka meddelandet",
  "email.error.subtitle":
    "Försök igen, och om problemet kvarstår, använd ett av de andra kontaktalternativen.",
  "email.send": "Skicka",
  "email.sending": "Skickar…",
  "email.sendCopyToSelf": "Skicka kopia till mig själv",
  "email.messageTemplate": "Hej kundtjänst\n \nJag har en fråga: [](question)",
  "email.messageTemplatePostProcess": "Från: [](userEmail)\n \n[](message)",
  "emailSuccess.title": "Vi har mottagen ditt meddelande",
  "emailSuccess.subtitle":
    "En från vårt team kommer att kontakta dig på den e-postadress du angav.",
  "emailSuccess.finish": "Avsluta",
  "sidebar.close": "Stäng",
  "sidebar.originalLink": "Se original",
  "topquestions.title": "Vad andra har frågat:",
  "globalError.description": "Något gick fel.",
  "globalError.retryButtonLabel": "Försök igen",
  expand: "Se mer",
  collapse: "Se mindre",
};

var uiLabelTranslations = { da, en, de, fr, sv };

export default uiLabelTranslations;
