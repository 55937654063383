
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/host/frames/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import {Position} from "../../types/index.js";
import Launcher from "./Launcher.svelte.js";
import LauncherButton from "./LauncherButton.svelte.js";
import Sidebar from "./Sidebar.svelte.js";
import SupportSidebar from "./SupportSidebar.svelte.js";
import Overlay from "./Overlay.svelte.js";
import ElementFrame from "./Element.svelte.js";
const initHotReload = (component) => {
  if (undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/host/frames/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
    undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/host/frames/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
    undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/host/frames/index.js'), env: __SNOWPACK_ENV__ }).hot */ .dispose(() => {
      component.$destroy();
    });
  }
};
const mountLauncher = (appearance) => {
  const launcherButton = new LauncherButton({
    target: document.body,
    props: {
      appearance
    }
  });
  const launcher = new Launcher({
    target: document.body
  });
  initHotReload(launcher);
  initHotReload(launcherButton);
};
const mountElement = (element_selector) => {
  if (!element_selector) {
    throw new Error("invalid container config");
  }
  const target = document.querySelector(element_selector);
  if (!target) {
    throw new Error("target element not found");
  }
  const element = new ElementFrame({target});
  initHotReload(element);
};
const mountSidebar = () => {
  const sidebar = new Sidebar({target: document.body});
  initHotReload(sidebar);
};
const mountSupportSidebar = () => {
  const supportSidebar = new SupportSidebar({target: document.body});
  initHotReload(supportSidebar);
};
const mountOverlay = () => {
  const overlay = new Overlay({target: document.body});
  initHotReload(overlay);
};
export const mountFrames = (instance) => {
  const {
    behaviour: {containers},
    appearance,
    supportChannels
  } = instance.configuration;
  switch (containers.searchbar) {
    case Position.ELEMENT: {
      mountElement(containers.element_selector);
      break;
    }
    case Position.OVERLAY: {
      mountOverlay();
      break;
    }
    case Position.LAUNCHER: {
      mountLauncher(appearance);
      break;
    }
    default:
      break;
  }
  if (containers.results === Position.SIDEBAR || containers.content === Position.SIDEBAR) {
    mountSidebar();
  }
  if (supportChannels.iframe.target === "overlay") {
    mountSupportSidebar();
  }
};
