/* src/host/frames/SupportSidebar.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	append,
	append_styles,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	null_to_empty,
	safe_not_equal,
	space,
	src_url_equal,
	svg_element,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import translations from '../../../_snowpack/pkg/@raffle-ai/ui-label-translations.js';
import { FrameIdentifierClassname } from '../../constants.js';
import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { ActionName } from '../../types/index.js';
import NoScroll from './NoScroll.svelte.js';
import { get, connect, disconnect, dispatch } from '../../sharedState/inHost.js';

function add_css(target) {
	append_styles(target, "raffle__SupportSidebar--1ued3vr", ".container.raffle__SupportSidebar--1ued3vr{display:flex;flex-direction:column;position:fixed;top:0px;right:initial;left:0px;width:100%;height:100%;opacity:1;box-sizing:border-box;border-right:1px solid #e5e1e1;transform:translateX(-120%);z-index:2147483647;transition-duration:200ms;transition-property:transform;transition-timing-function:ease-in-out;background-color:#fff;box-shadow:0px 9px 12px 0px rgba(0, 0, 0, 0.25)}@media(min-width: 500px){.container.raffle__SupportSidebar--1ued3vr{width:450px;height:100vh}}.container.open.raffle__SupportSidebar--1ued3vr{transform:translateX(0)}.support-sidebar-header.raffle__SupportSidebar--1ued3vr{width:100%;background-color:white;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid rgb(229, 231, 235)}iframe.raffle__SupportSidebar--1ued3vr{position:relative;left:0;right:0;height:100%;border:0}.original-link.raffle__SupportSidebar--1ued3vr{text-decoration:underline;color:#555;padding:0px 15px}.close-btn.raffle__SupportSidebar--1ued3vr{display:flex;align-items:center;border-style:none;color:black;font-size:16px;padding:12px;margin-left:auto;background-color:unset}.close-btn.raffle__SupportSidebar--1ued3vr:hover{cursor:pointer}.close-btn-icon.raffle__SupportSidebar--1ued3vr{margin-left:8px}");
}

// (57:2) {#if open}
function create_if_block(ctx) {
	let iframe;
	let iframe_class_value;
	let iframe_src_value;

	return {
		c() {
			iframe = element("iframe");
			attr(iframe, "class", iframe_class_value = "" + (null_to_empty(FrameIdentifierClassname) + " raffle__SupportSidebar--1ued3vr"));
			attr(iframe, "data-testid", "raffle-frame__support_sidebar");
			attr(iframe, "title", "raffle support sidebar");
			if (!src_url_equal(iframe.src, iframe_src_value = /*config*/ ctx[2].supportChannels.iframe.url)) attr(iframe, "src", iframe_src_value);
		},
		m(target, anchor) {
			insert(target, iframe, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(iframe);
		}
	};
}

function create_fragment(ctx) {
	let noscroll;
	let t0;
	let div1;
	let div0;
	let button;
	let span;
	let t2;
	let svg;
	let line0;
	let line1;
	let t3;
	let current;
	let mounted;
	let dispose;

	noscroll = new NoScroll({
			props: {
				apply: /*open*/ ctx[0] && /*narrowViewport*/ ctx[1]
			}
		});

	let if_block = /*open*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			create_component(noscroll.$$.fragment);
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			button = element("button");
			span = element("span");
			span.textContent = `${/*buttonText*/ ctx[3]}`;
			t2 = space();
			svg = svg_element("svg");
			line0 = svg_element("line");
			line1 = svg_element("line");
			t3 = space();
			if (if_block) if_block.c();
			attr(line0, "x1", "18");
			attr(line0, "y1", "6");
			attr(line0, "x2", "6");
			attr(line0, "y2", "18");
			attr(line1, "x1", "6");
			attr(line1, "y1", "6");
			attr(line1, "x2", "18");
			attr(line1, "y2", "18");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "width", "24");
			attr(svg, "height", "24");
			attr(svg, "fill", "none");
			attr(svg, "stroke", "currentColor");
			attr(svg, "stroke-width", "2");
			attr(svg, "stroke-linecap", "round");
			attr(svg, "stroke-linejoin", "round");
			attr(svg, "class", "close-btn-icon raffle__SupportSidebar--1ued3vr");
			attr(button, "class", "close-btn raffle__SupportSidebar--1ued3vr");
			attr(div0, "class", "support-sidebar-header raffle__SupportSidebar--1ued3vr");
			attr(div1, "class", "container raffle__SupportSidebar--1ued3vr");
			toggle_class(div1, "open", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			mount_component(noscroll, target, anchor);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button);
			append(button, span);
			append(button, t2);
			append(button, svg);
			append(svg, line0);
			append(svg, line1);
			append(div1, t3);
			if (if_block) if_block.m(div1, null);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*close*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const noscroll_changes = {};
			if (dirty & /*open, narrowViewport*/ 3) noscroll_changes.apply = /*open*/ ctx[0] && /*narrowViewport*/ ctx[1];
			noscroll.$set(noscroll_changes);

			if (/*open*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*open*/ 1) {
				toggle_class(div1, "open", /*open*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(noscroll.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(noscroll.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(noscroll, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let open = get('supportSidebarOpen');
	let narrowViewport = get('narrowViewport');
	let config = get('config');
	let locale = get('locale');

	const buttonText = translations[locale]
	? translations[locale]['sidebar.close']
	: 'Close';

	function handleOpen(val) {
		$$invalidate(0, open = val);
	}

	function handleNarrowViewport(n) {
		$$invalidate(1, narrowViewport = n);
	}

	function close() {
		dispatch({ type: ActionName.CLOSE_SUPPORT_SIDEBAR });
	}

	onMount(() => {
		connect('narrowViewport', handleNarrowViewport);
		connect('supportSidebarOpen', handleOpen);
	});

	onDestroy(() => {
		disconnect('narrowViewport', handleNarrowViewport);
		disconnect('supportSidebarOpen', handleOpen);
	});

	return [open, narrowViewport, config, buttonText, close];
}

class SupportSidebar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, add_css);
	}
}

export default SupportSidebar;