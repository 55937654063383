import * as event from "../event.js";
const hide = () => {
  const wrapper = document.getElementById(WRAPPER_ID);
  if (wrapper) {
    wrapper.style.transform = "translateX(999999px)";
  }
};
const show = () => {
  const wrapper = document.getElementById(WRAPPER_ID);
  if (wrapper) {
    wrapper.style.transform = "translateX(0)";
  }
};
const waitForWrapper = (callback) => {
  if (document.getElementById(WRAPPER_ID)) {
    callback();
  } else {
    const wrapperObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes) {
          for (const node of mutation.addedNodes) {
            const element = node;
            if (element.id === WRAPPER_ID) {
              observer.disconnect();
              callback();
            }
          }
        }
      }
    });
    wrapperObserver.observe(document.body, {
      attributes: false,
      childList: true,
      subtree: false
    });
  }
};
export const init = (openOnInit, onlyHide) => {
  waitForWrapper(hide);
  if (onlyHide) {
    return;
  }
  window.raffleApi.addEventListener("chat:start", (e, question) => {
    window.raffleApi.hide();
    show();
    window.smartsupp("chat:open");
    window.smartsupp("chat:show");
    if (question) {
      window.smartsupp("chat:message", question);
    }
    const minimizeObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes") {
          const widgetSyle = getComputedStyle(mutation.target);
          if (widgetSyle.getPropertyValue("height") === "56px") {
            window.smartsupp("chat:hide");
            hide();
            window.raffleApi.show();
            event.triggerListeners("chat:end");
            observer.disconnect();
          }
        }
      }
    });
    const smartsuppNode = document.getElementById(WRAPPER_ID);
    if (smartsuppNode) {
      minimizeObserver.observe(smartsuppNode, {
        attributes: true,
        childList: false,
        subtree: false
      });
    }
  });
  event.triggerListeners("chat:ready");
  if (openOnInit) {
    waitForWrapper(() => event.triggerListeners("chat:start"));
  }
};
