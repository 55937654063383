import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import {ActionName, DeviceType} from "../types/index.js";
import {STORE_KEY} from "../constants.js";
import {sentryClient, addSentryTags} from "./sentry.js";
import {fetchInstance} from "./api.js";
import mountGuard from "./mountGuard.js";
import {dispatch} from "../sharedState/inHost.js";
import {mountFrames} from "./frames/index.js";
import * as event from "./event.js";
import {chatIntegrationHide, chatIntegrationInit} from "./chatVendors/index.js";
import {get} from "../utils/storage.js";
const init = async () => {
  const scriptTag = document.getElementById("raffle-sdk");
  if (!scriptTag) {
    throw new Error("no raffle-sdk script tag");
  }
  const uid = scriptTag.getAttribute("data-uid");
  if (!uid) {
    throw new Error("no uid on raffle-sdk script tag");
  }
  addSentryTags({uid});
  try {
    const instance = await fetchInstance(uid);
    if (window.location !== window.parent.location) {
      if (instance.configuration.supportChannels.chat.enabled) {
        chatIntegrationHide(instance.configuration.supportChannels.chat);
      }
      return;
    }
    const shouldMount = mountGuard(instance);
    if (!shouldMount)
      return;
    const deviceType = scriptTag?.getAttribute("data-dedicated-mobile") === "" ? DeviceType.DEDICATED_MOBILE : DeviceType.WEB;
    const narrowViewport = window.innerWidth < 500;
    initState(instance, deviceType, narrowViewport);
    mountFrames(instance);
    const openOnInit = false;
    chatIntegrationInit(instance.configuration.supportChannels.chat, openOnInit);
    initHistory();
    window.raffleApi = {
      addEventListener: event.addListener,
      removeEventListener: event.removeListener,
      hide: () => event.triggerListeners("search:hide"),
      show: () => event.triggerListeners("search:show"),
      open: () => event.triggerListeners("client:open"),
      close: () => event.triggerListeners("client:close"),
      log: () => console.log("raffleApi is available on window")
    };
    event.addListener("client:open", () => dispatch({type: ActionName.ACTIVE_TOGGLE}));
    event.addListener("client:close", () => dispatch({type: ActionName.ACTIVE_TOGGLE}));
    event.addListener("chat:disable", () => dispatch({type: ActionName.DISABLE_CHAT}));
  } catch (error) {
    if (__SNOWPACK_ENV__.NODE_ENV === "production") {
      const e = error;
      sentryClient.report(e);
    } else {
      throw error;
    }
  }
};
const initState = (instance, deviceType, narrowViewport) => {
  const storeDump = get({key: STORE_KEY(instance.uid)});
  dispatch({
    type: ActionName.INIT,
    payload: {
      instance,
      store: storeDump,
      deviceType,
      narrowViewport
    }
  });
};
const initHistory = () => {
  const pushState = history.pushState;
  const replaceState = history.replaceState;
  history.pushState = (...args) => {
    pushState.apply(history, args);
    window.dispatchEvent(new Event("pushstate"));
    window.dispatchEvent(new Event("locationchange"));
  };
  history.replaceState = (...args) => {
    replaceState.apply(history, args);
    window.dispatchEvent(new Event("replacestate"));
    window.dispatchEvent(new Event("locationchange"));
  };
  window.addEventListener("popstate", () => {
    window.dispatchEvent(new Event("locationchange"));
  });
  window.addEventListener("locationchange", () => {
    dispatch({
      type: ActionName.URL_CHANGE,
      payload: window.location.href
    });
  });
};
export default init;
