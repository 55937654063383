const extractText = (node) => node.child?.reduce((acc, curr) => {
  if (curr.node === "text" && curr.text) {
    return `${acc} ${curr.text.trim()}`;
  }
  if (curr.child?.length) {
    return `${acc} ${extractText(curr)}`;
  }
  return acc;
}, "").trim();
export const cardsFromSearchResponse = (sections) => {
  return sections.reduce((acc, section, index) => {
    const answer = acc.find((a) => a.answer_id === section.answer_id);
    const {
      answer_id,
      answer_version,
      title,
      source_url,
      internal,
      preview_type,
      page_number,
      content,
      source_type,
      ...rest
    } = section;
    const text = extractText(content);
    if (!text || text.length === 0) {
      return acc;
    }
    if (answer) {
      answer.sections.push({
        ...rest,
        content,
        model_rank: index,
        text
      });
    } else {
      acc.push({
        answer_id,
        answer_version,
        title,
        source_url,
        preview_type,
        internal,
        page_number,
        source_type,
        sections: [
          {
            ...rest,
            content,
            model_rank: index,
            text
          }
        ]
      });
    }
    return acc;
  }, []);
};
export const buildMapFromResponse = (sections) => sections.reduce((acc, cur, index) => {
  return {...acc, [cur.section_id]: {...cur, model_rank: index}};
}, {});
