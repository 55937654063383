import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
let retryCount = 0;
export const init = (openOnInit, onlyHide) => {
  if (!window.SnapEngage) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 100) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("SnapEngage chat init failed, window.SnapEngage not available");
      } else {
        init(openOnInit, onlyHide);
      }
    }, 100);
    return;
  }
  window.SnapEngage.hideButton();
  if (onlyHide) {
    return;
  }
  window.SnapEngage.setCallback("Close", function() {
    window.SnapEngage.hideButton();
    window.raffleApi.show();
  });
  window.SnapEngage.setCallback("Minimize", function(isMinimized) {
    const chatIsActive = window.SnapEngage.isChatInProgress();
    if (isMinimized && !chatIsActive) {
      window.SnapEngage.hideButton();
      window.raffleApi.show();
    }
  });
  window.raffleApi.addEventListener("chat:start", (_, question) => {
    window.raffleApi.hide();
    window.SnapEngage.openProactiveChat(true, true, "", false);
    window.SnapEngage.setCustomField("description", question);
  });
  if (openOnInit) {
    event.triggerListeners("chat:start");
  }
};
