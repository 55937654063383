const listeners = {
  all: [],
  "search:ready": [],
  "search:show": [],
  "search:hide": [],
  "client:open": [],
  "client:opened": [],
  "client:close": [],
  "client:closed": [],
  "chat:start": [],
  "chat:ready": [],
  "chat:end": [],
  "chat:disable": []
};
export const addListener = (event, fn) => {
  if (!listeners[event]) {
    throw new Error(`unknow event: ${event}`);
  }
  listeners[event].push(fn);
};
export const removeListener = (event, fn) => {
  if (!listeners[event]) {
    throw new Error(`unknow event: ${event}`);
  }
  listeners[event] = listeners[event].filter((listener) => listener !== fn);
};
export const triggerListeners = (event, payload) => {
  if (!listeners[event]) {
    throw new Error(`unknow event: ${event}`);
  }
  listeners[event].forEach((fn) => fn(event, payload));
  listeners.all.forEach((fn) => fn(event, payload));
};
