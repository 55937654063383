import {v4 as uuid} from "../../_snowpack/pkg/uuid.js";
import {
  ActionName,
  AnswerClickTarget,
  DeviceType,
  ElementFrame,
  Hint,
  IframeTarget,
  LauncherFrame,
  Position,
  RequestState,
  SuggestionsPosition,
  SupportChannel,
  View,
  ViewExtended
} from "../types/index.js";
import {buildMapFromResponse, cardsFromSearchResponse} from "./normalise.js";
import {supportChannelAvailability} from "./supportChannelAvailability.js";
export const initialState = {
  instance_uid: "initial-uid",
  locale: "en",
  knowledgeBaseId: 0,
  config: {
    disabled: false,
    appearance: {
      main_color: "hsl(209, 100%, 50%)",
      button_text_color: "	hsl(0,0%,100%)"
    },
    behaviour: {
      containers: {
        searchbar: Position.ELEMENT,
        results: Position.DEFAULT,
        content: Position.DEFAULT,
        element_selector: ""
      }
    },
    supportChannels: {
      phone: {
        enabled: false
      },
      email: {
        enabled: false
      },
      iframe: {
        enabled: false
      },
      chat: {
        enabled: false,
        inject_first_message: true
      }
    },
    text_overrides: {},
    gdprNotification: {}
  },
  availableSupportChannels: [],
  currentSupportChannel: void 0,
  sessionId: uuid(),
  url: window.location.href,
  deviceType: DeviceType.WEB,
  narrowViewport: false,
  active: false,
  sidebarOpen: false,
  supportSidebarOpen: false,
  focusedClientId: void 0,
  query: "",
  current: {
    answer_id: void 0,
    section_id: void 0
  },
  answers: {
    data: {},
    requestState: RequestState.INITIAL
  },
  answersCache: {
    click: {},
    feedback: {}
  },
  suggestions: {
    data: [],
    requestState: RequestState.INITIAL
  },
  search: {
    data: [],
    requestState: RequestState.INITIAL,
    sectionsDictionary: {}
  },
  hint: Hint.NONE,
  token: "",
  elementFrame: ElementFrame.DEFAULT,
  launcherFrame: LauncherFrame.DEFAULT,
  clientFooter: false,
  sidebarFooter: false,
  suggestionsPosition: SuggestionsPosition.HIDDEN,
  clientView: View.DEFAULT,
  sidebarView: View.DEFAULT,
  launcherView: View.DEFAULT,
  viewBack: View.DEFAULT,
  error: "Something went wrong",
  lastActive: new Date()
};
export const reducer = (state, action) => {
  const isLauncher = state.config?.behaviour.containers.searchbar === Position.LAUNCHER;
  const isResultsDefault = state.config?.behaviour.containers.results === Position.DEFAULT;
  const isResultsSidebar = state.config?.behaviour.containers.results === Position.SIDEBAR;
  const isContentDefault = state.config?.behaviour.containers.content === Position.DEFAULT;
  const isContentSidebar = state.config?.behaviour.containers.content === Position.SIDEBAR;
  const hasSuportChannels = state.availableSupportChannels.length > 0;
  switch (action.type) {
    case ActionName.ACTIVE_TOGGLE: {
      const isActivating = state.active === false;
      return {
        ...state,
        active: !state.active,
        ...spread(isLauncher && isActivating, {
          launcherView: ViewExtended.SUGGESTIONS,
          launcherFrame: LauncherFrame.COLLAPSED,
          query: ""
        }),
        ...spread(isLauncher && isActivating && hasSuportChannels, {
          clientFooter: true,
          launcherFrame: LauncherFrame.COLLAPSED_SUPPORT
        })
      };
    }
    case ActionName.ANSWER_CLOSE: {
      return {
        ...state,
        current: {...initialState.current},
        sidebarOpen: isResultsSidebar,
        ...spread(isResultsDefault && !isLauncher, {
          clientView: View.RESULTS
        }),
        ...spread(isLauncher, {
          launcherView: View.RESULTS
        }),
        ...spread(isResultsSidebar, {
          sidebarView: View.RESULTS
        })
      };
    }
    case ActionName.ANSWER_SELECT: {
      if (action.payload.target === AnswerClickTarget.INSTANT_ANSWER) {
        return {
          ...state,
          ...cacheAnswerClick(action.payload.answer_id, state)
        };
      }
      return {
        ...state,
        current: {
          answer_id: action.payload.answer_id,
          section_id: action.payload.section_id
        },
        currentSupportChannel: initialState.currentSupportChannel,
        ...cacheAnswerClick(action.payload.answer_id, state),
        ...spread(isContentDefault && !isLauncher, {
          elementFrame: ElementFrame.EXPANDED_RELATIVE,
          clientView: View.CONTENT
        }),
        ...spread(isLauncher, {
          launcherView: View.CONTENT
        }),
        ...spread(isContentSidebar, {
          sidebarView: View.CONTENT,
          sidebarOpen: true
        }),
        ...spread(isLauncher, {
          launcherFrame: LauncherFrame.EXPANDED_FULL
        })
      };
    }
    case ActionName.CLEAR: {
      return {
        ...state,
        query: "",
        current: {...initialState.current},
        search: {...initialState.search},
        elementFrame: initialState.elementFrame,
        clientView: initialState.clientView,
        sidebarView: initialState.sidebarView,
        clientFooter: initialState.clientFooter,
        sidebarFooter: initialState.sidebarFooter,
        currentSupportChannel: initialState.currentSupportChannel,
        sidebarOpen: initialState.sidebarOpen,
        ...spread(isLauncher, {
          launcherView: state.suggestions.requestState === RequestState.ERROR ? View.DEFAULT : ViewExtended.SUGGESTIONS,
          launcherFrame: state.suggestions.requestState === RequestState.ERROR ? LauncherFrame.COLLAPSED : LauncherFrame.DEFAULT
        }),
        ...spread(isLauncher && hasSuportChannels, {
          clientFooter: true,
          launcherFrame: state.suggestions.requestState === RequestState.ERROR || !state.suggestions.data?.length ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.DEFAULT
        })
      };
    }
    case ActionName.CLEAR_INPUT: {
      const {isInsideSidebar} = action.payload;
      return {
        ...state,
        query: "",
        ...spread(isLauncher, {
          launcherView: state.suggestions.requestState === RequestState.ERROR ? View.DEFAULT : ViewExtended.SUGGESTIONS,
          launcherFrame: state.suggestions.requestState === RequestState.ERROR ? LauncherFrame.COLLAPSED : LauncherFrame.DEFAULT
        }),
        ...spread(!isLauncher, {
          suggestionsPosition: isInsideSidebar ? SuggestionsPosition.SIDEBAR : SuggestionsPosition.ELEMENT
        }),
        ...spread(isLauncher && hasSuportChannels, {
          clientFooter: true,
          launcherFrame: state.suggestions.requestState === RequestState.ERROR || !state.suggestions.data?.length ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.DEFAULT
        })
      };
    }
    case ActionName.INIT: {
      const {
        deviceType,
        narrowViewport,
        instance: {
          configuration: config,
          instance_token: token,
          knowledge_base_id: knowledgeBaseId,
          uid: instance_uid,
          locale
        }
      } = action.payload;
      return {
        ...state,
        ...action.payload.store,
        url: state.url,
        ...supportChannelAvailability(config.supportChannels),
        config,
        token,
        locale,
        instance_uid,
        knowledgeBaseId,
        deviceType,
        narrowViewport
      };
    }
    case ActionName.SEARCH_REQUEST: {
      return {
        ...state,
        query: action.payload.query,
        hint: action.payload.hint,
        search: {
          ...state.search,
          requestState: RequestState.FETCHING
        },
        ...spread(isResultsSidebar, {
          sidebarView: View.LOADING
        }),
        ...spread(isResultsDefault && !isLauncher, {
          clientView: View.LOADING
        }),
        ...spread(isLauncher, {
          launcherView: View.LOADING
        })
      };
    }
    case ActionName.SEARCH_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          requestState: RequestState.SUCCESS,
          data: cardsFromSearchResponse(action.payload.data),
          sectionsDictionary: buildMapFromResponse(action.payload.data)
        },
        answersCache: initialState.answersCache,
        ...spread(isResultsSidebar, {
          sidebarOpen: true,
          sidebarView: View.RESULTS
        }),
        ...spread(isResultsDefault && !isLauncher, {
          elementFrame: ElementFrame.EXPANDED_RELATIVE,
          clientView: View.RESULTS
        }),
        ...spread(isLauncher, {
          launcherView: View.RESULTS,
          launcherFrame: LauncherFrame.EXPANDED_FULL
        }),
        ...spread(hasSuportChannels && isResultsDefault, {
          clientFooter: true
        }),
        ...spread(hasSuportChannels && isResultsSidebar, {
          sidebarFooter: true
        })
      };
    }
    case ActionName.SEARCH_FAILURE: {
      return {
        ...state,
        search: {
          ...initialState.search,
          requestState: RequestState.ERROR
        },
        ...spread(isResultsSidebar, {
          sidebarOpen: true,
          sidebarView: View.ERROR
        }),
        ...spread(isResultsDefault && !isLauncher, {
          elementFrame: ElementFrame.EXPANDED_RELATIVE,
          clientView: View.ERROR
        }),
        ...spread(isLauncher, {
          launcherView: View.ERROR,
          launcherFrame: LauncherFrame.EXPANDED_FULL
        }),
        ...spread(hasSuportChannels && isResultsDefault, {
          clientFooter: true
        }),
        ...spread(hasSuportChannels && isResultsSidebar, {
          sidebarFooter: true
        }),
        error: "Search failure"
      };
    }
    case ActionName.SUGGESTIONS_REQUEST: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          requestState: RequestState.FETCHING
        },
        ...spread(isLauncher, {
          launcherView: state.launcherView === View.DEFAULT || state.launcherView === ViewExtended.SUGGESTIONS ? View.LOADING : state.launcherView
        }),
        ...spread(isLauncher && hasSuportChannels, {
          clientFooter: true
        })
      };
    }
    case ActionName.SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        suggestions: {
          requestState: RequestState.SUCCESS,
          data: action.payload.questions || []
        },
        ...spread(isLauncher, {
          launcherView: state.launcherView === View.LOADING ? ViewExtended.SUGGESTIONS : state.launcherView,
          launcherFrame: state.launcherView === View.LOADING ? LauncherFrame.DEFAULT : state.launcherFrame
        }),
        ...spread(isLauncher && !action.payload.questions?.length, {
          clientView: View.DEFAULT,
          launcherFrame: hasSuportChannels ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.COLLAPSED
        })
      };
    }
    case ActionName.SUGGESTIONS_FAILURE: {
      return {
        ...state,
        error: "Suggestions failure",
        suggestions: {
          ...initialState.suggestions,
          requestState: RequestState.ERROR
        },
        ...spread(isLauncher && state.launcherView === View.LOADING, {
          launcherView: View.DEFAULT,
          launcherFrame: hasSuportChannels ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.COLLAPSED
        })
      };
    }
    case ActionName.ANSWER_REQUEST: {
      return {
        ...state,
        answers: {
          ...state.answers,
          requestState: RequestState.FETCHING
        },
        ...spread(isContentDefault && !isLauncher, {
          clientView: View.LOADING
        }),
        ...spread(isLauncher, {
          launcherView: View.LOADING
        }),
        ...spread(isContentSidebar, {
          sidebarView: View.LOADING
        })
      };
    }
    case ActionName.ANSWER_SUCCESS: {
      return {
        ...state,
        answers: {
          ...state.answers,
          requestState: RequestState.SUCCESS,
          data: {
            ...state.answers.data,
            [action.payload.id]: action.payload
          }
        },
        ...spread(isContentDefault && !isLauncher, {
          clientView: View.CONTENT
        }),
        ...spread(isLauncher, {
          launcherView: View.CONTENT
        }),
        ...spread(isContentSidebar, {
          sidebarView: View.CONTENT
        })
      };
    }
    case ActionName.ANSWER_FAILURE: {
      return {
        ...state,
        answers: {
          ...state.answers,
          requestState: RequestState.ERROR
        },
        ...spread(isContentDefault && !isLauncher, {
          clientView: View.ERROR
        }),
        ...spread(isLauncher, {
          launcherView: View.ERROR
        }),
        ...spread(isContentSidebar, {
          sidebarView: View.ERROR
        }),
        error: "Answer failure"
      };
    }
    case ActionName.ANSWER_ACCEPT:
    case ActionName.ANSWER_REJECT: {
      return {
        ...state,
        answersCache: {
          ...state.answersCache,
          feedback: {
            ...state.answersCache.feedback,
            [action.payload.answer_id]: action.payload.feedback
          }
        }
      };
    }
    case ActionName.FOCUS: {
      const shouldRenderSuggestions = !state.query.length && !isLauncher && state.suggestions.requestState === RequestState.SUCCESS;
      return {
        ...state,
        focusedClientId: action.payload.clientId,
        ...spread(shouldRenderSuggestions, {
          elementFrame: state.elementFrame === ElementFrame.EXPANDED_RELATIVE ? state.elementFrame : ElementFrame.EXPANDED,
          suggestionsPosition: action.payload.isInsideSidebar ? SuggestionsPosition.SIDEBAR : SuggestionsPosition.ELEMENT
        }),
        ...spread(state.suggestions.requestState === RequestState.ERROR && state.elementFrame === ElementFrame.DEFAULT, {
          elementFrame: ElementFrame.EXPANDED
        })
      };
    }
    case ActionName.FOCUS_BLUR: {
      return {
        ...state,
        focusedClientId: void 0,
        suggestionsPosition: initialState.suggestionsPosition,
        query: action.payload.length ? action.payload : state.query,
        ...spread(state.suggestions.requestState === RequestState.ERROR && state.clientView === View.DEFAULT, {
          elementFrame: ElementFrame.DEFAULT
        })
      };
    }
    case ActionName.GOTO_SUPPORT: {
      return {
        ...state,
        ...spread(isResultsSidebar, {
          sidebarOpen: true,
          sidebarView: View.SUPPORT,
          viewBack: state.sidebarView !== View.SUPPORT ? state.sidebarView : state.viewBack
        }),
        ...spread(isResultsDefault && !isLauncher, {
          clientView: View.SUPPORT,
          viewBack: state.clientView !== View.SUPPORT ? state.clientView : state.viewBack
        }),
        ...spread(isLauncher, {
          launcherFrame: LauncherFrame.EXPANDED,
          launcherView: View.SUPPORT,
          viewBack: state.launcherView !== View.SUPPORT ? state.launcherView : state.viewBack
        }),
        ...spread(state.currentSupportChannel !== void 0, {
          currentSupportChannel: initialState.currentSupportChannel
        })
      };
    }
    case ActionName.SELECT_SUPPORT_CHANNEL: {
      const shouldOpenSupportIframe = action.payload === SupportChannel.IFRAME && state.config.supportChannels.iframe.target === IframeTarget.OVERLAY;
      return {
        ...state,
        currentSupportChannel: action.payload,
        ...shouldOpenSupportIframe && {
          supportSidebarOpen: !state.supportSidebarOpen
        }
      };
    }
    case ActionName.CLOSE_SUPPORT_SIDEBAR: {
      return {
        ...state,
        supportSidebarOpen: false
      };
    }
    case ActionName.CLEAR_SELECTED_CHANNEL: {
      return {
        ...state,
        currentSupportChannel: void 0
      };
    }
    case ActionName.DISABLE_CHAT: {
      return {
        ...state,
        availableSupportChannels: state.availableSupportChannels?.filter((ch) => ch !== SupportChannel.CHAT),
        config: {
          ...state.config,
          supportChannels: {
            ...state.config.supportChannels,
            chat: {
              ...state.config.supportChannels.chat,
              enabled: false
            }
          }
        }
      };
    }
    case ActionName.GO_BACK: {
      const shouldLauncherFrameDefault = isLauncher && state.viewBack === ViewExtended.SUGGESTIONS;
      const shouldLauncherFrameExpand = isLauncher && (state.viewBack === View.CONTENT || state.viewBack === View.RESULTS);
      const shouldLauncherFrameCollapse = isLauncher && (state.viewBack === View.DEFAULT || state.viewBack === ViewExtended.SUGGESTIONS) && state.suggestions.requestState === RequestState.ERROR;
      return {
        ...state,
        viewBack: initialState.viewBack,
        ...spread(isResultsSidebar, {
          sidebarView: state.viewBack
        }),
        ...spread(isResultsDefault && !isLauncher, {
          clientView: state.viewBack
        }),
        ...spread(isLauncher, {
          launcherView: state.viewBack
        }),
        ...spread(shouldLauncherFrameDefault, {
          launcherFrame: LauncherFrame.DEFAULT
        }),
        ...spread(shouldLauncherFrameExpand, {
          launcherFrame: LauncherFrame.EXPANDED_FULL
        }),
        ...spread(shouldLauncherFrameCollapse, {
          launcherFrame: hasSuportChannels ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.COLLAPSED
        }),
        ...spread(isLauncher && state.viewBack === View.DEFAULT && !state.suggestions.data.length, {
          launcherFrame: hasSuportChannels ? LauncherFrame.COLLAPSED_SUPPORT : LauncherFrame.COLLAPSED
        }),
        currentSupportChannel: void 0
      };
    }
    case ActionName.URL_CHANGE: {
      return {
        ...state,
        url: action.payload
      };
    }
    case ActionName.RESET_ON_ERROR: {
      return {
        ...initialState,
        instance_uid: state.instance_uid,
        locale: state.locale,
        knowledgeBaseId: state.knowledgeBaseId,
        config: state.config,
        sessionId: state.sessionId,
        url: state.url,
        deviceType: state.deviceType,
        narrowViewport: state.narrowViewport,
        token: state.token,
        availableSupportChannels: state.availableSupportChannels
      };
    }
    default:
      return state;
  }
};
const spread = (exp, udpdateObj) => exp ? udpdateObj : {};
const cacheAnswerClick = (id, state) => ({
  answersCache: {
    ...state.answersCache,
    click: {
      ...state.answersCache.click,
      [id]: state.answersCache.click[id] ? state.answersCache.click[id] + 1 : 1
    }
  }
});
