import {differenceInMinutes, parseJSON} from "../../_snowpack/pkg/date-fns/esm.js";
import {v4 as uuid} from "../../_snowpack/pkg/uuid.js";
import {STORE_KEY} from "../constants.js";
import {set} from "../utils/storage.js";
const CACHED_ANSWERS_LIMIT = 11;
export const persist = (state, exclude = []) => {
  let newState = {...state};
  if (Object.keys(newState.answers.data).length > CACHED_ANSWERS_LIMIT) {
    newState = {...newState, answers: {...newState.answers, data: {}}};
  }
  exclude.forEach((key) => delete newState[key]);
  set({key: STORE_KEY(state.instance_uid), payload: newState});
};
const SESSION_MINUTES_LIMIT = 10;
export const regenerate = (state) => {
  const shouldRegenerate = differenceInMinutes(new Date(), parseJSON(state.lastActive)) > SESSION_MINUTES_LIMIT;
  return {
    ...state,
    lastActive: new Date(),
    ...shouldRegenerate && {
      sessionId: uuid(),
      answersCache: {click: {}, feedback: {}}
    }
  };
};
