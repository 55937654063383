export var Position;
(function(Position2) {
  Position2["DEFAULT"] = "DEFAULT";
  Position2["ELEMENT"] = "ELEMENT";
  Position2["LAUNCHER"] = "LAUNCHER";
  Position2["OVERLAY"] = "OVERLAY";
  Position2["SIDEBAR"] = "SIDEBAR";
  Position2["SIBLING"] = "SIBLING";
})(Position || (Position = {}));
export var RequestState;
(function(RequestState2) {
  RequestState2["INITIAL"] = "INITIAL";
  RequestState2["FETCHING"] = "FETCHING";
  RequestState2["SUCCESS"] = "SUCCESS";
  RequestState2["ERROR"] = "ERROR";
})(RequestState || (RequestState = {}));
export var SharedState;
(function(SharedState2) {
  SharedState2["GET"] = "SHARED_STATE_GET";
  SharedState2["GOT"] = "SHARED_STATE_GOT";
  SharedState2["DISPATCH"] = "SHARED_STATE_DISPATCH";
  SharedState2["SUBSCRIBE"] = "SHARED_STATE_SUBSCRIBE";
  SharedState2["UNSUBSCRIBE"] = "SHARED_STATE_UNSUBSCRIBE";
  SharedState2["NOTIFY"] = "SHARED_STATE_NOTIFY";
})(SharedState || (SharedState = {}));
export var View;
(function(View2) {
  View2["DEFAULT"] = "DEFAULT";
  View2["CONTENT"] = "CONTENT";
  View2["RESULTS"] = "RESULTS";
  View2["SUPPORT"] = "SUPPORT";
  View2["LOADING"] = "LOADING";
  View2["ERROR"] = "ERROR";
})(View || (View = {}));
export var ViewExtended;
(function(ViewExtended2) {
  ViewExtended2["SUGGESTIONS"] = "SUGGESTIONS";
})(ViewExtended || (ViewExtended = {}));
export var SuggestionsPosition;
(function(SuggestionsPosition2) {
  SuggestionsPosition2["HIDDEN"] = "HIDDEN";
  SuggestionsPosition2["ELEMENT"] = "ELEMENT";
  SuggestionsPosition2["SIDEBAR"] = "SIDEBAR";
})(SuggestionsPosition || (SuggestionsPosition = {}));
export var ElementFrame;
(function(ElementFrame2) {
  ElementFrame2["DEFAULT"] = "DEFAULT";
  ElementFrame2["EXPANDED"] = "EXPANDED";
  ElementFrame2["EXPANDED_RELATIVE"] = "EXPANDED_RELATIVE";
})(ElementFrame || (ElementFrame = {}));
export var LauncherFrame;
(function(LauncherFrame2) {
  LauncherFrame2["DEFAULT"] = "DEFAULT";
  LauncherFrame2["COLLAPSED"] = "COLLAPSED";
  LauncherFrame2["COLLAPSED_SUPPORT"] = "COLLAPSED_SUPPORT";
  LauncherFrame2["EXPANDED"] = "EXPANDED";
  LauncherFrame2["EXPANDED_FULL"] = "EXPANDED_FULL";
})(LauncherFrame || (LauncherFrame = {}));
export var SupportChannel;
(function(SupportChannel2) {
  SupportChannel2["PHONE"] = "phone";
  SupportChannel2["CHAT"] = "chat";
  SupportChannel2["IFRAME"] = "iframe";
  SupportChannel2["EMAIL"] = "email";
})(SupportChannel || (SupportChannel = {}));
export var IframeTarget;
(function(IframeTarget2) {
  IframeTarget2["OVERLAY"] = "overlay";
  IframeTarget2["NEW_TAB"] = "new_tab";
})(IframeTarget || (IframeTarget = {}));
export var SupportChannelsGdprConfigType;
(function(SupportChannelsGdprConfigType2) {
  SupportChannelsGdprConfigType2["NEW_TAB"] = "new_tab";
  SupportChannelsGdprConfigType2["TOOLTIP"] = "tooltip";
})(SupportChannelsGdprConfigType || (SupportChannelsGdprConfigType = {}));
export var FeedbackOption;
(function(FeedbackOption2) {
  FeedbackOption2["INITIAL"] = "INITIAL";
  FeedbackOption2["THUMBS_UP"] = "THUMBS_UP";
  FeedbackOption2["THUMBS_DOWN"] = "THUMBS_DOWN";
})(FeedbackOption || (FeedbackOption = {}));
export var DeviceType;
(function(DeviceType2) {
  DeviceType2["DEDICATED_MOBILE"] = "dedicated_mobile";
  DeviceType2["WEB"] = "web";
})(DeviceType || (DeviceType = {}));
export var Hint;
(function(Hint2) {
  Hint2["SUGGESTION"] = "suggestion";
  Hint2["AUTOCOMPLETE"] = "autocomplete";
  Hint2["NONE"] = "none";
})(Hint || (Hint = {}));
export var AnswerClickTarget;
(function(AnswerClickTarget2) {
  AnswerClickTarget2["TITLE"] = "title";
  AnswerClickTarget2["SECTION"] = "section";
  AnswerClickTarget2["INSTANT_ANSWER"] = "instant_answer";
})(AnswerClickTarget || (AnswerClickTarget = {}));
export var SourceType;
(function(SourceType2) {
  SourceType2["DRUPAL_ECONOMIC"] = "drupal_economic";
  SourceType2["ZENDESK"] = "zendesk";
  SourceType2["HUMANY"] = "humany";
  SourceType2["ZOHO"] = "zoho";
  SourceType2["SHAREPOINT"] = "sharepoint";
  SourceType2["WEB_SCRAPE"] = "web_scrape";
  SourceType2["MANUAL_UPLOAD"] = "manual_upload";
  SourceType2["INSTANT_ANSWERS"] = "instant_answers";
})(SourceType || (SourceType = {}));
