import {
  SharedState
} from "../types/index.js";
import {OriginKey} from "../constants.js";
import {postMessage} from "./postMessage.js";
import {reducer, initialState} from "./reducer.js";
import {persist, regenerate} from "./middleware.js";
import eventEmitter from "./eventEmitter.js";
import {postEvent} from "../host/api.js";
let state = {...initialState};
const subscribers = {__all__: []};
const callbacks = {__all__: []};
export const get = (key) => {
  return state[key];
};
export const dump = () => state;
export const dispatch = (action) => {
  let newState = reducer(state, action);
  newState = regenerate(newState);
  persist(newState);
  eventEmitter(newState, action, postEvent);
  const updatedKeys = Object.keys(state).filter((key) => JSON.stringify(state[key]) !== JSON.stringify(newState[key]));
  if (!updatedKeys.length) {
    return;
  }
  state = newState;
  updatedKeys.forEach((key) => {
    callbacks[key]?.forEach((callback) => callback(state[key]));
    subscribers[key]?.forEach((request_id) => {
      postMessage({
        type: SharedState.NOTIFY,
        payload: {value: state[key], request_id}
      });
    });
  });
  callbacks.__all__?.forEach((callback) => {
    callback(state);
  });
  subscribers.__all__?.forEach((request_id) => {
    postMessage({
      type: SharedState.NOTIFY,
      payload: {value: state, request_id}
    });
  });
};
export const connect = (key, callback) => {
  if (!callbacks[key])
    callbacks[key] = [];
  callbacks[key].push(callback);
};
export const disconnect = (key, callback) => {
  Object.keys(callbacks).forEach((callbackKey) => {
    if (callbackKey === key) {
      callbacks[callbackKey] = callbacks[callbackKey].filter((fn) => fn !== callback);
    }
  });
};
export const flushConnections = () => {
  Object.keys(subscribers).forEach((key) => subscribers[key] = []);
  Object.keys(callbacks).forEach((key) => callbacks[key] = []);
};
const subscribe = (key, request_id) => {
  if (!subscribers[key]) {
    subscribers[key] = [];
  }
  subscribers[key].push(request_id);
  if (key === "__all__") {
    postMessage({
      type: SharedState.NOTIFY,
      payload: {value: state, request_id}
    });
  } else {
    postMessage({
      type: SharedState.NOTIFY,
      payload: {value: get(key), request_id}
    });
  }
};
const unsubscribe = (key, request_id) => {
  if (!subscribers[key])
    return;
  subscribers[key] = subscribers[key].filter((id) => id !== request_id);
};
window.addEventListener("message", (e) => {
  const type = e?.data?.type;
  const origin = e?.data?.origin;
  const payload = e?.data?.payload || {};
  if (origin !== OriginKey) {
    return;
  }
  switch (type) {
    case SharedState.SUBSCRIBE: {
      const {key, request_id} = payload;
      subscribe(key, request_id);
      break;
    }
    case SharedState.DISPATCH: {
      dispatch(payload);
      break;
    }
    case SharedState.GET: {
      const {request_id, key} = payload;
      postMessage({
        type: SharedState.GOT,
        payload: {value: key === "__all__" ? dump() : get(key), request_id}
      });
      break;
    }
    case SharedState.UNSUBSCRIBE: {
      const {request_id, key} = payload;
      unsubscribe(key, request_id);
      break;
    }
    default:
      return;
  }
});
