import {sentryClient} from "../sentry.js";
import * as event from "../event.js";
let retryCount = 0;
export const init = (openOnInit, onlyHide) => {
  if (!window.zE || !window.$zopim?.livechat?.window) {
    setTimeout(() => {
      retryCount++;
      if (retryCount > 100) {
        event.triggerListeners("chat:disable");
        sentryClient.captureMessage("zendesk chat init failed, window.zE not available");
      } else {
        init(openOnInit, onlyHide);
      }
    }, 100);
    return;
  }
  window.zE("webWidget", "hide");
  if (onlyHide) {
    return;
  }
  window.raffleApi.addEventListener("chat:start", (event2, question) => {
    if (!window.$zopim?.livechat?.window) {
      throw new Error("zendesk chat start failed. window.$zopim.livechat.window not available");
    }
    window.$zopim?.livechat?.window?.show();
    window.raffleApi.hide();
    if (question) {
      window.zE("webWidget", "chat:send", question);
    }
  });
  window.zE("webWidget:on", "userEvent", function(e) {
    const widgetState = window.zE("webWidget:get", "display");
    const {action} = e;
    if (action === "Web Widget Minimised" && widgetState === "launcher" || action === "Help Center Shown" && widgetState === "chat") {
      window.zE("webWidget", "hide");
      event.triggerListeners("chat:end");
      window.raffleApi.show();
    }
  });
  event.triggerListeners("chat:ready", window.zE);
  if (openOnInit) {
    event.triggerListeners("chat:start", window.zE);
  }
};
