import browserSupport from "./browserSupport.js";
const mountGuard = (instance) => {
  if (!instance) {
    throw new Error("not mounting, instance not found");
  }
  if (!browserSupport(navigator.userAgent)) {
    throw new Error("not mounting, browser not supported");
  }
  if (instance.configuration.disabled) {
    return false;
  }
  return true;
};
export default mountGuard;
