/* src/host/frames/LauncherButton.svelte generated by Svelte v3.43.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	append_styles,
	attr,
	check_outros,
	create_in_transition,
	create_out_transition,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	safe_not_equal,
	svg_element,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { onDestroy, onMount } from '../../../_snowpack/pkg/svelte.js';
import { fade } from '../../../_snowpack/pkg/svelte/transition.js';
import { circInOut } from '../../../_snowpack/pkg/svelte/easing.js';
import { ActionName } from '../../types/index.js';
import { get, connect, disconnect, dispatch } from '../../sharedState/inHost.js';
import { darken } from '../../utils/hsl.js';

function add_css(target) {
	append_styles(target, "raffle__LauncherButton--1s8n9fe", "button.raffle__LauncherButton--1s8n9fe:hover{background-color:var(--hover-color);box-shadow:0 25px 12px rgba(0, 0, 0, 0.15);transform:scale(1.01);cursor:pointer}button.raffle__LauncherButton--1s8n9fe,button.raffle__LauncherButton--1s8n9fe:active{position:fixed;bottom:15px;right:15px;height:56px;width:56px;border-radius:100%;border-width:1px;border-style:solid;background-color:var(--main-color);border-color:var(--border-color);box-shadow:0px 10px 5px rgb(0 0 0 / 10%);transition-property:box-shadow, background-color, transform;transition-duration:340ms;transition-timing-function:ease-in-out}svg.raffle-search--inactive.raffle__LauncherButton--1s8n9fe{width:30px;fill:var(--button-text-color);transform:translateY(3px);position:absolute;top:14px;left:12px}svg.raffle-search--active.raffle__LauncherButton--1s8n9fe{display:none;stroke:var(--button-text-color);position:absolute}@media(min-width: 500px){button.raffle__LauncherButton--1s8n9fe,button.raffle__LauncherButton--1s8n9fe:active{bottom:40px;right:40px;height:62px;width:62px}svg.raffle-search--inactive.raffle__LauncherButton--1s8n9fe{width:31px;top:15px;left:15px}svg.raffle-search--active.raffle__LauncherButton--1s8n9fe{display:block;width:48px;top:6px;left:6px}}");
}

// (53:4) {:else}
function create_else_block(ctx) {
	let svg;
	let path;
	let svg_intro;
	let svg_outro;
	let current;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "d", "M54.026 0c3.12 0 4.252.325 5.393.935a6.36 6.36 0 012.646 2.646c.61 1.14.935 2.272.935 5.393v24.052c0 3.12-.325 4.252-.935 5.393a6.36 6.36 0 01-2.646 2.646c-1.14.61-2.272.935-5.393.935H24l-13.515 9.721a2 2 0 01-3.168-1.623V42c-1.426 0-2.714-.338-3.798-.946A6.688 6.688 0 01.946 38.48C.338 37.397 0 36.11 0 34.683V8.973c0-3.12.325-4.251.935-5.392A6.36 6.36 0 013.581.935C4.721.325 5.853 0 8.974 0h45.052zM29.481 29a2.36 2.36 0 00-1.756.744A2.455 2.455 0 0027 31.52c0 .687.242 1.272.725 1.756a2.391 2.391 0 001.756.725c.687 0 1.279-.242 1.775-.725A2.36 2.36 0 0032 31.519c0-.687-.248-1.279-.744-1.775A2.423 2.423 0 0029.48 29zm.555-21c-1.77 0-3.284.401-4.545 1.203-1.26.803-2.158 1.75-2.691 2.845a7.73 7.73 0 00-.8 3.428c0 .547.05 1.043.152 1.49l.066.26 3.527.438c-.17-.462-.254-1.057-.254-1.787 0-1.264.388-2.358 1.164-3.282.775-.924 1.903-1.386 3.381-1.386 1.382 0 2.449.401 3.2 1.204.752.802 1.128 1.787 1.128 2.954 0 1.423-.573 2.565-1.72 3.425l-.208.148-1.6 1.095c-2.012 1.361-3.018 3.257-3.018 5.689 0 .479.01.86.027 1.143l.01.133h3.2a6.996 6.996 0 01-.037-.693c0-1.51.473-2.633 1.417-3.368l.183-.133 1.673-1.13C36.764 19.998 38 17.883 38 15.33c0-1.97-.721-3.683-2.164-5.142C34.394 8.73 32.461 8 30.036 8z");
			attr(svg, "class", "raffle-search--inactive raffle__LauncherButton--1s8n9fe");
			attr(svg, "viewBox", "0 0 63 53");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
			current = true;
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (svg_outro) svg_outro.end(1);
				svg_intro = create_in_transition(svg, fade, { duration: 250, delay: 250 });
				svg_intro.start();
			});

			current = true;
		},
		o(local) {
			if (svg_intro) svg_intro.invalidate();
			svg_outro = create_out_transition(svg, fade, { duration: 150, delay: 0 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(svg);
			if (detaching && svg_outro) svg_outro.end();
		}
	};
}

// (42:4) {#if active}
function create_if_block(ctx) {
	let svg;
	let path;
	let svg_intro;
	let svg_outro;
	let current;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "d", "M18 6L6 18M6 6l12 12");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke-width", "2");
			attr(svg, "stroke-linecap", "round");
			attr(svg, "stroke-linejoin", "round");
			attr(svg, "class", "raffle-search--active raffle__LauncherButton--1s8n9fe");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
			current = true;
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (svg_outro) svg_outro.end(1);
				svg_intro = create_in_transition(svg, /*spin*/ ctx[3], { delay: 150, duration: 250 });
				svg_intro.start();
			});

			current = true;
		},
		o(local) {
			if (svg_intro) svg_intro.invalidate();
			svg_outro = create_out_transition(svg, /*spin*/ ctx[3], { delay: 0, duration: 250 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(svg);
			if (detaching && svg_outro) svg_outro.end();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let button;
	let current_block_type_index;
	let if_block;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*active*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			button = element("button");
			if_block.c();
			attr(button, "data-testid", "raffle-search-launcher-button");
			attr(button, "style", /*styleString*/ ctx[1]);
			attr(button, "class", "raffle__LauncherButton--1s8n9fe");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			if_blocks[current_block_type_index].m(button, null);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*handleClick*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					
				}

				transition_in(if_block, 1);
				if_block.m(button, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { appearance } = $$props;
	const borderColor = darken(appearance.main_color, 10);
	const hoverColor = darken(appearance.main_color, 4);
	const styleString = `--main-color: ${appearance.main_color}; --button-text-color: ${appearance.button_text_color}; --border-color: ${borderColor}; --hover-color: ${hoverColor}`;
	let active = get('active');

	function handleClick() {
		dispatch({ type: ActionName.ACTIVE_TOGGLE });
	}

	function handleActive(val) {
		$$invalidate(0, active = val);
	}

	onMount(() => {
		connect('active', handleActive);
	});

	onDestroy(() => {
		disconnect('active', handleActive);
	});

	function spin(node, { duration, delay }) {
		return {
			duration,
			delay,
			css: t => {
				const eased = circInOut(t);
				return `transform: rotate(${eased * 90}deg); opacity: ${eased}`;
			}
		};
	}

	$$self.$$set = $$props => {
		if ('appearance' in $$props) $$invalidate(4, appearance = $$props.appearance);
	};

	return [active, styleString, handleClick, spin, appearance];
}

class LauncherButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { appearance: 4 }, add_css);
	}
}

export default LauncherButton;