// @for-internal-use
// Chromium based browsers: Chrome, Brave, new Opera, new Edge
const CHROME_REGEXP = /^\s*at (?:(.*?) ?\()?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i;
// gecko regex: `(?:bundle|\d+\.js)`: `bundle` is for react native, `\d+\.js` also but specifically for ram bundles because it
// generates filenames without a prefix like `file://` the filenames in the stacktrace are just 42.js
// We need this specific case for now because we want no other regex to match.
const GECKO_REGEXP = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:file|https?|blob|chrome|webpack|resource|moz-extension).*?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js))(?::(\d+))?(?::(\d+))?\s*$/i;
const WINJS_REGEXP = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
const UNKNOWN_FUNCTION = '?';
const AUTH_HEADER = 'X-Sentry-Auth';
//                          |protocol     |public |secret      |host      |port       |projectId
//                          |             |       |            |          |           |
const DSN_REGEXP = /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+))?@)([\w.-]+)(?::(\d+))?\/(.+)/;

// @for-internal-use
function computeStackTraceFromStackProp(ex) {
    if (!ex.stack) {
        return null;
    }
    const stack = [];
    const lines = ex.stack.split('\n');
    let parts;
    let element;
    for (let i = 0; i < lines.length; ++i) {
        // tslint:disable-next-line:no-conditional-assignment
        if ((parts = CHROME_REGEXP.exec(lines[i]))) {
            element = {
                // working with the regexp above is super painful. it is quite a hack, but just stripping the `address at `
                // prefix here seems like the quickest solution for now.
                filename: parts[2] && parts[2].indexOf('address at ') === 0
                    ? parts[2].substr('address at '.length)
                    : parts[2],
                function: parts[1] || UNKNOWN_FUNCTION,
                lineno: parts[3] ? +parts[3] : null,
                colno: parts[4] ? +parts[4] : null,
            };
            // tslint:disable-next-line:no-conditional-assignment
        }
        else if ((parts = WINJS_REGEXP.exec(lines[i]))) {
            element = {
                filename: parts[2],
                function: parts[1] || UNKNOWN_FUNCTION,
                lineno: +parts[3],
                colno: parts[4] ? +parts[4] : null,
            };
            // tslint:disable-next-line:no-conditional-assignment
        }
        else if ((parts = GECKO_REGEXP.exec(lines[i]))) {
            if (i === 0 && !parts[5] && ex.columnNumber !== void 0) {
                // FireFox uses this awesome columnNumber property for its top frame
                // Also note, Firefox's column number is 0-based and everything else expects 1-based,
                // so adding 1
                // NOTE: this hack doesn't work if top-most frame is eval
                // TODO: why do not we include column into an interface?
                stack[0].column = ex.columnNumber + 1;
            }
            element = {
                filename: parts[3],
                function: parts[1] || UNKNOWN_FUNCTION,
                lineno: parts[4] ? +parts[4] : null,
                colno: parts[5] ? +parts[5] : null,
            };
        }
        else {
            continue;
        }
        if (!element.function && element.lineno) {
            element.function = UNKNOWN_FUNCTION;
        }
        stack.push(element);
    }
    if (!stack.length) {
        return null;
    }
    return {
        value: extractMessage(ex),
        type: ex.name,
        stacktrace: { frames: stack.reverse() },
    };
}
function extractMessage(ex) {
    const message = (ex && ex.message) || 'No error message';
    return message.split('\n').filter((s) => !!s)[0];
}
function computeStackTrace(ex) {
    // tslint:disable:no-unsafe-any
    try {
        const stack = computeStackTraceFromStackProp(ex);
        if (stack) {
            return stack;
        }
    }
    catch (e) {
        //
    }
    return {
        value: extractMessage(ex),
        type: ex && ex.name,
        stacktrace: { frames: [] },
    };
}

const ɵ0 = function __assign(target) {
    const length = arguments.length;
    for (let i = 1; i < length; i++) {
        // eslint-disable-next-line prefer-rest-params
        const source = arguments[i];
        for (const property in source) {
            if (Object.prototype.hasOwnProperty.call(source, property)) {
                target[property] = source[property];
            }
        }
    }
    return target;
};
const __assign = Object.assign || ɵ0;
class MicroSentryClient {
    constructor(options) {
        if (options.dsn) {
            const searched = DSN_REGEXP.exec(options.dsn);
            const dsn = searched ? searched.slice(1) : [];
            const pathWithProjectId = dsn[5].split('/');
            const path = pathWithProjectId.slice(0, -1).join('/');
            this.apiUrl =
                dsn[0] +
                    '://' +
                    dsn[3] +
                    (dsn[4] ? ':' + dsn[4] : '') +
                    (path ? '/' + path : '') +
                    '/api/' +
                    pathWithProjectId.pop() +
                    '/store/';
            this.authHeader =
                'Sentry sentry_version=7,sentry_key=' +
                    dsn[1] +
                    (dsn[2] ? ',sentry_secret=' + dsn[2] : '');
        }
        this.environment = options.environment;
    }
    prepare(error) {
        return __assign(this.getRequestBlank(), {
            exception: { values: [computeStackTrace(error)] },
        });
    }
    report(error) {
        this.send(this.prepare(error));
    }
    send(request) {
        if (!this.apiUrl || !request) {
            return;
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.apiUrl, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader(AUTH_HEADER, this.authHeader || '');
        xhr.send(JSON.stringify(request));
    }
    getRequestBlank() {
        return {
            platform: 'javascript',
            sdk: {
                name: 'micro-sentry.javascript.browser',
                version: '0.0.0',
            },
            timestamp: Date.now() / 1000,
            request: {
                url: window.location.toString(),
                headers: {
                    'User-Agent': window.navigator.userAgent,
                },
            },
            environment: this.environment,
        };
    }
}

function isMatchingPattern(value, pattern) {
    if (Object.prototype.toString.call(pattern) === '[object RegExp]') {
        return pattern.test(value);
    }
    if (typeof pattern === 'string') {
        return value.indexOf(pattern) !== -1;
    }
    return false;
}

class BrowserMicroSentryClient extends MicroSentryClient {
    constructor(options) {
        super(options);
        this.options = options;
        this.destroyed = false;
        this._state = {};
        const { plugins = [], beforeSend = (req) => req, beforeBreadcrumb = (breadcrumb) => breadcrumb, blacklistUrls = [], ignoreErrors = [], release = undefined, } = this.options || {};
        this.plugins = plugins.map((Plugin) => new Plugin(this));
        this.beforeSend = beforeSend;
        this.beforeBreadcrumb = beforeBreadcrumb;
        this.blacklistUrls = blacklistUrls;
        this.ignoreErrors = ignoreErrors;
        this.release = release;
    }
    get state() {
        return this._state;
    }
    clearState() {
        this._state = {};
    }
    setTags(tags) {
        this.setKeyState('tags', Object.assign({}, tags));
        return this;
    }
    setTag(key, value) {
        this.extendState({ tags: { [key]: value } });
        return this;
    }
    setExtra(key, value) {
        this.extendState({ extra: { [key]: value } });
        return this;
    }
    setExtras(extras) {
        this.setKeyState('extra', Object.assign({}, extras));
        return this;
    }
    setUser(user) {
        this.setKeyState('user', Object.assign({}, user));
        return this;
    }
    clone() {
        const client = new BrowserMicroSentryClient(Object.assign(Object.assign({}, this.options), { plugins: [] }));
        client.extendState(this.state);
        return client;
    }
    withScope(fn) {
        const clone = this.clone();
        fn(clone);
        clone.destroy();
        this.setBreadcrumbs(undefined);
    }
    addBreadcrumb(breadcrumb) {
        this.extendState({
            breadcrumbs: [
                Object.assign({ timestamp: Date.now() / 1000 }, this.beforeBreadcrumb(breadcrumb)),
            ],
        });
    }
    setBreadcrumbs(breadcrumbs) {
        this.setKeyState('breadcrumbs', breadcrumbs);
    }
    captureMessage(message, level) {
        this.send(Object.assign(Object.assign({}, this.getRequestBlank()), { message,
            level }));
    }
    destroy() {
        this.destroyed = true;
        this.plugins.forEach((plugin) => {
            if (plugin.destroy) {
                plugin.destroy();
            }
        });
    }
    isIgnoredError(event) {
        if (!this.ignoreErrors.length) {
            return false;
        }
        return this.getPossibleEventMessages(event).some((message) => this.ignoreErrors.some((pattern) => isMatchingPattern(message, pattern)));
    }
    getRequestBlank() {
        return Object.assign(Object.assign({}, super.getRequestBlank()), this.state);
    }
    send(request) {
        if (this.destroyed ||
            this.isDeniedUrl(request) ||
            this.isIgnoredError(request)) {
            return;
        }
        super.send(this.beforeSend(Object.assign({ release: this.release }, request)));
        this.setBreadcrumbs(undefined);
    }
    getPossibleEventMessages(event) {
        if (event.message) {
            return [event.message];
        }
        if (event.exception) {
            try {
                const { type = '', value = '' } = (event.exception.values && event.exception.values[0]) || {};
                return [`${value}`, `${type}: ${value}`];
            }
            catch (e) {
                return [];
            }
        }
        return [];
    }
    isDeniedUrl(event) {
        if (!this.blacklistUrls.length) {
            return false;
        }
        const url = this.getEventFilterUrl(event);
        return !url
            ? false
            : this.blacklistUrls.some((pattern) => isMatchingPattern(url, pattern));
    }
    getEventFilterUrl(event) {
        try {
            if (event.exception) {
                const frames = event.exception.values &&
                    event.exception.values[0].stacktrace &&
                    event.exception.values[0].stacktrace.frames;
                return (frames && frames[frames.length - 1].filename) || null;
            }
            return null;
        }
        catch (e) {
            return null;
        }
    }
    extendState(newState) {
        this._state = Object.keys(newState).reduce((acc, key) => {
            const stateValue = this.state[key];
            const stateArray = Array.isArray(stateValue) ? stateValue : null;
            const newStateValue = newState[key];
            const newStateArray = Array.isArray(newStateValue)
                ? newStateValue
                : null;
            return Object.assign(Object.assign({}, acc), { [key]: stateArray || newStateArray
                    ? [...(stateArray || []), ...(newStateArray || [])]
                    : Object.assign(Object.assign({}, (typeof stateValue !== 'string' ? stateValue : {})), (typeof newStateValue !== 'string' ? newStateValue : {})) });
        }, {});
    }
    setKeyState(key, value) {
        this._state[key] = value;
    }
}

export { BrowserMicroSentryClient };
